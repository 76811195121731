export class UnsubscribeManager {
  protected unsubscribers: (() => void)[] = []

  add(subscriber: () => void) {
    this.unsubscribers.push(subscriber)
  }

  runAll() {
    this.unsubscribers.forEach((sub) => sub())
  }

  dispose() {
    this.runAll()
    this.unsubscribers = []
  }
}
