import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
  Query,
} from 'firebase/firestore'
import {
  addDoc,
  and,
  collection,
  collectionGroup,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { ObservableCollection } from '../../firestore-mobx'
import type { FirestoreRoomStateAnswer } from './schema'
import { empty, schema, writeSchema } from './schema'
import { RoomStateAnswer } from '../../models/RoomStateAnswer'
import { StaticModelCollection } from '../../firestore-mobx/model'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'

export * from './schema'

function buildEmpty(): FirestoreRoomStateAnswer {
  return {
    answer: 0,
    roomId: '',
    slideQuestionId: '',
    userId: '',
    updatedAt: new Date(),
  }
}

const converter: FirestoreDataConverter<FirestoreRoomStateAnswer> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreRoomStateAnswer>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    const parsed = schema.safeParse(data)
    if (parsed.success) {
      return parsed.data
    } else {
      console.error('Invalid data', snapshot.id, snapshot.data(), parsed.error)
      return buildEmpty()
    }
  },
}

function getColGroupRef(firestore: Firestore): Query<FirestoreRoomStateAnswer> {
  return collectionGroup(firestore, 'answer').withConverter(converter)
}

export const getColRef = (
  firestore: Firestore,
  roomId: string,
  userOrRoomId: string
): CollectionReference<FirestoreRoomStateAnswer, DocumentData> => {
  return collection(
    firestore,
    'room_state',
    roomId,
    'user_answer',
    userOrRoomId,
    'answer'
  ).withConverter(converter)
}

export const buildEmptyRoomStateAnswersObservableCollection = () => {
  return new ObservableCollection<FirestoreRoomStateAnswer>(undefined)
}

export const buildRoomStateAnswersObservableCollection = (
  firestore: Firestore,
  roomId: string,
  userOrRoomId: string
) => {
  return new ObservableCollection<FirestoreRoomStateAnswer>(
    getColRef(firestore, roomId, userOrRoomId)
  )
}

export const upsertUserAnswer = async (
  repository: FirebaseRepository,
  questionId: string,
  {
    userId,
    roomId,
    assignmentId,
    sectionId,
    answer,
    answerId,
    answerList,
    isGroupAnswer,
  }: {
    userId: string
    roomId: string
    assignmentId?: string
    sectionId?: string
    answerId?: string
    answer: number
    answerList?: number[]
    isGroupAnswer: boolean
  }
) => {
  const subjectId = isGroupAnswer ? roomId : userId

  // touch the root user answer document
  const uaRef = collection(
    repository.firestore,
    'room_state',
    roomId,
    'user_answer'
  )
  const uaDocRef = doc(uaRef, subjectId)
  const uaData = { updatedAt: serverTimestamp() }
  void setDoc(uaDocRef, uaData)

  const data = {
    answer: answer,
    answerList: answerList || null,
    assignmentId: assignmentId,
    roomId: roomId,
    sectionId: sectionId,
    slideQuestionId: questionId,
    userId: userId,
    updatedAt: serverTimestamp(),
  }

  const collectionRef = getColRef(repository.firestore, roomId, subjectId)

  if (!answerId) {
    // search for existing answer
    const answers = await fetchRoomStateAnswersForQuestion(repository, {
      roomId,
      userId,
      slideQuestionId: questionId,
      isGroupAnswer,
    })
    if (answers.length > 0) {
      answerId = answers[answers.length - 1].id
    }
  }

  if (answerId) {
    const docRef = doc(collectionRef, answerId)
    return updateDoc(docRef, data)
  } else {
    return addDoc(collectionRef, data)
  }
}

export function submitUserAnswer(
  firestore: Firestore,
  {
    roomId,
    userId,
    answerId,
    isGroupAnswer,
  }: {
    roomId: string
    userId: string
    answerId: string
    isGroupAnswer: boolean
  }
) {
  const subjectId = isGroupAnswer ? roomId : userId

  const collectionRef = getColRef(firestore, roomId, subjectId)

  const docRef = doc(collectionRef, answerId)

  void updateDoc(docRef, {
    submitted: true,
  })
}

export const buildEmptyRoomStateAnswerCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: RoomStateAnswer,
    empty: empty,
  })
}

export const getRoomStateAnswersForInstructor = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomStateAnswer)
}

export const getRoomStateAnswers = (
  repository: FirebaseRepository,
  params: {
    roomId: string
    userId: string
  }
) => {
  const colRef = getColRef(repository.firestore, params.roomId, params.userId)

  return modelListStream(repository, colRef, RoomStateAnswer)
}

export const getRoomStateAnswersForGroup = (
  repository: FirebaseRepository,
  params: {
    roomId: string
  }
) => {
  return getRoomStateAnswers(repository, {
    roomId: params.roomId,
    userId: params.roomId,
  })
}

// fetch all answers for a given question and return document, not stream
export const fetchRoomStateAnswersForQuestion = async (
  repository: FirebaseRepository,
  {
    roomId,
    userId,
    slideQuestionId,
    isGroupAnswer,
  }: {
    roomId: string
    userId: string
    slideQuestionId: string
    isGroupAnswer: boolean
  }
) => {
  const subjectId = isGroupAnswer ? roomId : userId
  const q = query(
    getColRef(repository.firestore, roomId, subjectId),
    where('slideQuestionId', '==', slideQuestionId)
  )

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, RoomStateAnswer)
  })
}
