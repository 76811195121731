import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import {
  SectionState,
  createSection,
  getSectionsStreamForInstructor,
  getSectionsStreamForInstructors,
} from '../firestore/Section'
import { Cubit } from './core'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { type StaticModelCollection } from '../firestore-mobx/model'
import { Section } from '../models/Section'
import { getTAInstructorIDs } from '../firestore/UserProfile'
import { deleteTARecordFromAppUser, getUsers } from '../firestore/PublicUser'
import { PublicUser } from '../models/PublicUser'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import { SectionAssignment } from '../models/SectionAssignment'

export class InstructorLibraryCubit extends Cubit {
  repository: FirebaseRepository
  userId: string
  role: string

  @observable showCompleted = false

  sections: StaticModelCollection<Section>

  assignmentsForSection = observable.map<
    string,
    StaticModelCollection<SectionAssignment>
  >()

  TAInstructors: StaticModelCollection<PublicUser>
  TAInstructorIds = observable.array<string>([])

  instructorUserId?: string
  instructorUser: PublicUser

  constructor(
    repository: FirebaseRepository,
    role: string,
    instructorUserId?: string
  ) {
    super()
    makeObservable(this)
    this.instructorUserId = instructorUserId
    this.userId = repository.uid
    this.repository = repository
    this.role = role

    this.sections = Section.emptyCollection(repository)
    this.TAInstructors = PublicUser.emptyCollection(repository)
    this.instructorUser = PublicUser.empty(repository)
  }

  initialize(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const cubit = this
    if (this.role === 'ta') {
      this.addStream(getTAInstructorIDs(this.repository), (instructorIds) => {
        runInAction(() => {
          this.TAInstructorIds.replace(instructorIds)
        })
      })
      // when instructor IDs updates re-init the instructor profile and sections streams
      this.addReaction({
        whenThisChanges: () => this.TAInstructorIds.length,
        thenRunThisCode: () => {
          const sectionsStreamLabel = 'ta-sections-stream'
          const instructorsStreamLabel = 'ta-instructors-stream'
          this.removeStream(sectionsStreamLabel)
          this.removeStream(instructorsStreamLabel)
          this.addStream(
            getSectionsStreamForInstructors(
              this.repository,
              this.TAInstructorIds
            ),
            (sections) => {
              this.sections.replaceModels(sections)
              this.addStreamsForSections()
            },
            { name: sectionsStreamLabel }
          )
          cubit.addStream(
            getUsers(cubit.repository, { userIds: cubit.TAInstructorIds }),
            (instructors) => cubit.TAInstructors.replaceModels(instructors)
          )
        },
      })
      return
    }
    this.addStream(
      getSectionsStreamForInstructor(this.repository, {
        instructorUserId: this.instructorUserId,
      }),
      (sections) => {
        this.sections.replaceModels(sections)
        this.addStreamsForSections()
      }
    )
    // if we are impersonating an instructor, get the instructor's profile
    if (this.instructorUserId) {
      this.addStream(
        getUsers(this.repository, { userIds: [this.instructorUserId] }),
        (instructors) => {
          this.instructorUser.replaceModel(instructors[0])
          return
        },
        { name: 'instructor-profile' }
      )
    }
  }

  addStreamsForSections() {
    this.sections.models.forEach((section) => {
      this.addStreamsForSection(section)
    })
  }

  @action
  addStreamsForSection(section: Section) {
    this.assignmentsForSection.set(
      section.id,
      SectionAssignment.emptyCollection(this.repository)
    )
    this.addStream(
      getSectionAssignments(this.repository, { sectionId: section.id }),
      (assignments) => {
        this.assignmentsForSection.get(section.id)?.replaceModels(assignments)
      }
    )
  }

  @action
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  @computed
  get visibleSections() {
    // Order the sections based on dart rules
    const sortedModels = this.sections.models.slice().sort((a, b) => {
      if (a.data.sectionState === b.data.sectionState) {
        if (!a.data.updatedAt || !b.data.updatedAt) {
          if (a.data.className !== b.data.className) {
            return a.data.className.localeCompare(b.data.className)
          } else {
            return a.data.sectionName.localeCompare(b.data.sectionName)
          }
        }
        return b.data.updatedAt.getTime() - a.data.updatedAt.getTime()
      }
      return a.data.sectionState - b.data.sectionState
    })

    if (this.showCompleted) return sortedModels
    return sortedModels.filter((section) => {
      return section.data.sectionState !== SectionState.completed
    })
  }

  @computed
  get sectionsByInstructor() {
    const sectionsByInstructorId: Record<
      string,
      { instructor: PublicUser; sections: Section[] }
    > = {}
    const instructorMap = Object.fromEntries(
      this.TAInstructors.models.map((instructor) => [instructor.id, instructor])
    )
    this.visibleSections.forEach((section) => {
      const instructorId = section.data.instructorUserId
      if (!sectionsByInstructorId[instructorId]) {
        const instructor =
          instructorId in instructorMap
            ? instructorMap[instructorId]
            : PublicUser.empty(this.repository)
        sectionsByInstructorId[instructorId] = { instructor, sections: [] }
      }
      sectionsByInstructorId[instructorId].sections.push(section)
    })
    return sectionsByInstructorId
  }

  @computed
  get sectionDataLoading() {
    const waitingForTAData = this.role === 'ta' && this.TAInstructors.isLoading
    return this.sections.isLoading || waitingForTAData
  }

  createSection(
    className: string,
    sectionName: string,
    instructorUserId?: string
  ) {
    if (this.role === 'ta' && !instructorUserId) {
      throw new Error('instructorUserId is required for TA to create a section')
    }
    return createSection(this.repository, {
      className,
      sectionName,
      userId: instructorUserId ?? this.userId,
    })
  }

  retireAsTA(instructorUserId: string) {
    return deleteTARecordFromAppUser(this.repository.firestore, {
      userId: this.userId,
      instructorUserId,
    })
  }
}
