import {
  collection,
  doc,
  getDoc,
  setDoc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsProcessingVideo } from '../../models/SettingsProcessingVideo'
import type { FirestoreSettingsProcessingVideo } from './schema'
import { schema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSettingsProcessingVideo> = {
  toFirestore: (user: FirestoreSettingsProcessingVideo) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

export const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'processing_video'
  )
}

export const fetchSettingsProcessingVideo = async (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDoc(docRef)

  return convertDocumentSnapshotToModel(
    repository,
    doc,
    SettingsProcessingVideo
  )
}

export const getSettingsProcessingVideo = (repository: FirebaseRepository) => {
  const docRef = getDocRef(repository.firestore)

  return modelItemStream(repository, docRef, SettingsProcessingVideo)
}

export const uploadSettingsProcessingVideo = async (
  repository: FirebaseRepository,
  file: File
) => {
  const reference = ref(
    repository.storage,
    'settings/processing_video/video.webm'
  )

  await uploadBytes(reference, file, {
    contentType: 'video/webm',
  })

  const url = await getDownloadURL(reference)

  const docRef = getDocRef(repository.firestore)

  return setDoc(
    docRef,
    {
      videoURL: url,
      storageURL: `gs://${reference.bucket}/${reference.fullPath}`,
    },
    { merge: true }
  )
}
