import type {
  DocumentData,
  DocumentReference,
  FieldValue,
} from 'firebase/firestore'
import {
  collection,
  doc,
  getDocs,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  CollectionReference,
  serverTimestamp,
  addDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore'
import { StaticModelCollection } from '../../firestore-mobx/model'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideQuestion } from '../../models/SlideQuestion'
import type { FirestoreSlideQuestion } from './schema'
import { empty, schema } from './schema'

const converter: FirestoreDataConverter<FirestoreSlideQuestion> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })

    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  { slideDeckId }: { slideDeckId: string }
): CollectionReference<FirestoreSlideQuestion, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'question'
  ).withConverter(converter)
}

// @ts-expect-error - just a matter of time until this is used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  {
    slideDeckId,
    slideQuestionId,
  }: { slideDeckId: string; slideQuestionId: string }
): DocumentReference<FirestoreSlideQuestion, DocumentData> => {
  return doc(
    getColRef(firestore, {
      slideDeckId: slideDeckId,
    }),
    slideQuestionId
  )
}

export const buildEmptyQuestionCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: SlideQuestion,
    empty: empty,
  })
}

/**
 * Get List of SlideQuestion models from Firestore
 *
 * @param repository - FirebaseRepository instance
 * @param slideDeckId - ID of the SlideDeck to get questions for
 *
 * @returns Stream for SlideQuestion snapshots
 */
export const getSlideQuestions = (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const ref = getColRef(repository.firestore, { slideDeckId })

  return modelListStream(repository, ref, SlideQuestion)
}

export const fetchSlideQuestions = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const docRef = getColRef(repository.firestore, params)

  const docs = await getDocs(docRef)

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideQuestion)
  })
}

export type QuestionFieldsForUpload = Omit<FirestoreSlideQuestion, 'updatedAt'>

export const saveSlideDeckQuestion = async (
  repository: FirebaseRepository,
  {
    questionFields,
    slideDeckId,
    questionId,
  }: {
    slideDeckId: string
    questionFields: QuestionFieldsForUpload
    questionId?: string
  }
) => {
  interface WriteInterface extends Omit<FirestoreSlideQuestion, 'updatedAt'> {
    updatedAt: FieldValue
  }

  const dataToWrite: WriteInterface = {
    ...questionFields,
    updatedAt: serverTimestamp(),
  }

  const ref = questionId
    ? doc(getColRef(repository.firestore, { slideDeckId }), questionId)
    : getColRef(repository.firestore, { slideDeckId })

  if (ref instanceof CollectionReference) {
    return await addDoc(ref, dataToWrite)
  }

  //@ts-expect-error - todo: not sure why the type system hates this
  return await updateDoc(ref, dataToWrite)
}

export const deleteSlideDeckQuestion = async (
  repository: FirebaseRepository,
  {
    questionId,
    slideDeckId,
  }: {
    questionId: string
    slideDeckId: string
  }
) => {
  await deleteDoc(
    doc(getColRef(repository.firestore, { slideDeckId }), questionId)
  )
}
