import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  documentId,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import { Catalog } from '../../models/Catalog'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreCatalog } from './schema'
import { schema } from './schema'
import { asyncExpand } from '../../util/asyncExpand'
import { StreamController } from 'tricklejs'
import { fetchAppUsers } from '../AppUser'
import { getTAInstructorIDs } from '../UserProfile'

const converter: FirestoreDataConverter<FirestoreCatalog> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreCatalog> => {
  return collection(firestore, 'catalog').withConverter(converter)
}

export const getCatalogs = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  return modelListStream(repository, ref, Catalog)
}

export const getCatalog = (
  repository: FirebaseRepository,
  {
    catalogId,
  }: {
    catalogId: string
  }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, catalogId)
  return modelItemStream(repository, docRef, Catalog)
}

export const getAllCatalogIds = (repository: FirebaseRepository) => {
  // get my instructor ids
  const stream = new StreamController<string[]>()
  let cancelled = false
  let instructorsStreamResults: string[] | undefined
  let myCatalogsStreamResult: string[] | undefined
  const emitIfBothResults = () => {
    if (!instructorsStreamResults || !myCatalogsStreamResult) return
    stream.add(
      Array.from(
        new Set([...instructorsStreamResults, ...myCatalogsStreamResult])
      )
    )
  }
  const instructorIdsStream = getTAInstructorIDs(repository).listen(
    async (instructorIds) => {
      if (cancelled) return
      if (!instructorIds.length || !repository.currentUser)
        instructorsStreamResults = []
      else {
        const catalogsAccessibleByUsers = await fetchCatalogsAccessibleByUsers(
          repository,
          {
            userIds: instructorIds,
          }
        )
        const uniqueCatalogIds = Array.from(
          new Set(Object.values(catalogsAccessibleByUsers).flat())
        )
        instructorsStreamResults = uniqueCatalogIds
      }
      emitIfBothResults()
    }
  )
  const myIdsStream = getCatalogIdsForCurrentUser(repository).listen(
    (catalogIds) => {
      if (cancelled) return
      myCatalogsStreamResult = catalogIds
      emitIfBothResults()
    }
  )

  stream.onCancel = () => {
    cancelled = true
    instructorIdsStream.cancel()
    myIdsStream.cancel()
    stream.close()
  }
  return stream.stream
}

export const getCatalogIdsForCurrentUser = (repository: FirebaseRepository) => {
  if (!repository.currentUser) throw new Error('User not logged in')
  const userCollectionRef = collection(
    repository.firestore,
    'user_profile',
    repository.currentUser.uid,
    'catalogs'
  )
  return collectionSnapshots(userCollectionRef).map((snapshot) =>
    snapshot.docs.map((doc) => doc.id)
  )
}

export const getMyCatalogs = (repository: FirebaseRepository) => {
  const controller = new StreamController<Catalog[]>()
  let cancelled = false
  const stream = getAllCatalogIds(repository).listen(async (catalogIds) => {
    if (cancelled) return
    if (!catalogIds.length) {
      controller.add([])
    } else {
      const colRef = getColRef(repository.firestore)
      const q = query(colRef, where(documentId(), 'in', catalogIds))
      const docs = await getDocs(q)

      const catalogs = docs.docs.map((doc) => {
        return convertDocumentSnapshotToModel(repository, doc, Catalog)
      })
      controller.add(catalogs)
    }
  })
  controller.onCancel = () => {
    cancelled = true
    stream.cancel()
    controller.close()
  }
  return controller.stream
}

export const getCatalogsForUser = (
  repository: FirebaseRepository,
  { userId }: { userId: string }
) => {
  // first fetch the document ids under
  // the user's catalog collection
  const userCollectionRef = collection(
    repository.firestore,
    'user_profile',
    userId,
    'catalogs'
  )
  //parse the ids via a stream, not a catalog just care about the ids
  const stream = collectionSnapshots(userCollectionRef).map((snapshot) => {
    const catalogIds = snapshot.docs.map((doc) => doc.id)
    if (!catalogIds.length) {
      const controller = new StreamController<Catalog[]>()
      controller.add([])
      controller.close()
      return controller.stream
    }
    const catalogRef = getColRef(repository.firestore)
    const catalogQuery = query(
      catalogRef,
      where(documentId(), 'in', catalogIds)
    )
    return modelListStream(repository, catalogQuery, Catalog)
  })
  return asyncExpand(stream, (data) => data)
}

/**
 * returns an object with the keys being set to the userId and the array being a list of catalogIds
 */
export const fetchCatalogsAccessibleByUsers = async (
  repository: FirebaseRepository,
  { userIds }: { userIds: string[] }
) => {
  const catalogDocPromises = await Promise.all(
    userIds.map(async (userId) => {
      const userCollectionRef = collection(
        repository.firestore,
        'user_profile',
        userId,
        'catalogs'
      )
      const snapshot = await getDocs(userCollectionRef)
      return { id: userId, catalogIds: snapshot.docs.map((doc) => doc.id) }
    })
  )
  return Object.fromEntries(
    catalogDocPromises.map(({ id, catalogIds }) => [id, catalogIds])
  )
}

export const createCatalog = async (
  repository: FirebaseRepository,
  {
    catalogName,
    catalogDescription,
  }: {
    catalogName: string
    catalogDescription: string
  }
) => {
  const colRef = getColRef(repository.firestore)
  const catalogRef = await addDoc(colRef, {
    catalogName,
    catalogDescription,
    catalogSharedSectionIds: [],
    updatedAt: serverTimestamp(),
  })
  return catalogRef.id
}

export const updateCatalog = async (
  repository: FirebaseRepository,
  catalogId: string,
  {
    catalogName,
    catalogDescription,
  }: {
    catalogName: string
    catalogDescription: string
  }
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, catalogId)
  return updateDoc(docRef, {
    catalogName,
    catalogDescription,
    updatedAt: serverTimestamp(),
  })
}

export const deleteCatalog = async (
  repository: FirebaseRepository,
  catalogId: string
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, catalogId)
  return deleteDoc(docRef)
}

export const getAllAppUserWithCatalogAccess = (
  repository: FirebaseRepository,
  catalogId: string
) => {
  const groupRef = collectionGroup(repository.firestore, 'catalogs')
  const queryRef = query(groupRef, where('catalogId', '==', catalogId))

  return collectionSnapshots(queryRef).asyncMap(async (snapshot) => {
    const userIds = snapshot.docs
      .map((doc) => doc.ref.parent.parent?.id)
      .filter((id): id is string => !!id)

    const appUsers = await fetchAppUsers(repository, { userIds })
    return appUsers
  })
}

export const addSectionToCatalog = (
  repository: FirebaseRepository,
  {
    catalogId,
    sectionId,
  }: {
    catalogId: string
    sectionId: string
  }
) => {
  const batch = writeBatch(repository.firestore)

  const sectionRef = doc(collection(repository.firestore, 'section'), sectionId)
  const catalogRef = doc(collection(repository.firestore, 'catalog'), catalogId)

  batch.update(sectionRef, {
    shareable: true,
    updatedAt: serverTimestamp(),
  })

  batch.update(catalogRef, {
    catalogSharedSectionIds: arrayUnion(sectionId),
    updatedAt: serverTimestamp(),
  })

  return batch.commit()
}

export const removeSectionFromCatalog = async (
  repository: FirebaseRepository,
  {
    catalogId,
    sectionId,
  }: {
    catalogId: string
    sectionId: string
  }
) => {
  const batch = writeBatch(repository.firestore)

  const sectionRef = doc(collection(repository.firestore, 'section'), sectionId)
  const catalogRef = doc(collection(repository.firestore, 'catalog'), catalogId)
  const catalogsRef = collection(repository.firestore, 'catalog')

  const catalogsWithSectionRef = query(
    catalogsRef,
    where('catalogSharedSectionIds', 'array-contains', sectionId)
  )
  const catalogs = await getDocs(catalogsWithSectionRef)
  const otherCatalogs = catalogs.docs.filter((doc) => doc.id !== catalogId)

  // only remove shareable from section if not shared
  // as part of any other catalog
  if (otherCatalogs.length === 0) {
    batch.update(sectionRef, {
      shareable: false,
      updatedAt: serverTimestamp(),
    })
  }

  batch.update(catalogRef, {
    catalogSharedSectionIds: arrayRemove(sectionId),
    updatedAt: serverTimestamp(),
  })
  return batch.commit()
}
