import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// required int answer,
// required String id,
// required String roomId,
// required String slideQuestionId,
// required String userId,
// List<int>? answerList,
// String assignmentId,
// String sectionId,
// bool? submitted,
// int? updatedAt,
export const schema = z.object({
  answer: z.number(),
  roomId: z.string(),
  slideQuestionId: z.string(),
  userId: z.string(),
  answerList: z.array(z.number()).nullable().optional(),
  assignmentId: z.string().optional(),
  sectionId: z.string().optional(),
  submitted: z.boolean().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    answer: 0,
    roomId: '',
    slideQuestionId: '',
    userId: '',
    answerList: null,
    assignmentId: '',
    sectionId: '',
    submitted: false,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreRoomStateAnswer = z.infer<typeof schema>
