import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'
import { SlideQuestionType } from '../../models/SlideQuestionType'

const slideQuestionTypeSchema = z.nativeEnum(SlideQuestionType)

export const schema = z.object({
  answers: z.array(z.string()),
  question: z.string(),
  questionType: slideQuestionTypeSchema,
  correct: z.number().optional(),
  groupSlideId: z.string().nullish().optional(),
  slideId: z.string().optional().nullable(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    answers: [],
    question: '',
    questionType: 0,
    groupSlideId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export interface FirestoreSlideQuestion extends z.infer<typeof schema> {}
