import type {
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
} from 'livekit-client'
import type { MeetingCubit } from '../MeetingCubit'

export class BroadcastMonitor {
  videoMonitorInterval: NodeJS.Timeout | null = null
  audioMonitorInterval: NodeJS.Timeout | null = null
  lastVideoStats: VideoReceiverStats | null = null
  lastAudioStats: AudioReceiverStats | null = null

  audioBroadcastPacketsReceived = 0
  audioBroadcastPacketsDropped = 0
  videoBroadcastPacketsReceived = 0
  videoBroadcastPacketsDropped = 0
  videoBroadcastFramesReceived = 0
  videoBroadcastFramesDropped = 0
  videoBroadcastFramesDecoded = 0

  meeting: MeetingCubit

  monitoredSlide: number

  constructor(meeting: MeetingCubit) {
    this.meeting = meeting
    this.monitoredSlide = meeting.activeSlide
  }

  getBreakoutParticipant(): RemoteParticipant | undefined {
    if (!this.meeting.livekitRoom) return

    const participants = Array.from(
      this.meeting.livekitRoom.remoteParticipants.values()
    )

    const breakoutParticipant = participants.find(
      (p) => p.identity === 'Breakout Learning'
    )
    return breakoutParticipant
  }

  getBreakoutParticipantTrackPublications() {
    const breakoutParticipant = this.getBreakoutParticipant()
    if (!breakoutParticipant) return []
    return Array.from(breakoutParticipant?.trackPublications.values())
  }

  attach() {
    this.videoMonitorInterval = setInterval(async () => {
      const publications = this.getBreakoutParticipantTrackPublications()
      const videoPublication = publications.find((p) => p.kind === 'video')
      if (!videoPublication) return
      const track = videoPublication.videoTrack
      if (!track) return
      // The following is the equivalent of the commented out line below
      // if (!(track instanceof RemoteVideoTrack)) return
      // The reason I'm doing is because Typescript exports do not impact the bundles
      // but instanceof checks do. So I'm using a type assertion and then checking for
      // existence of the receiver property, which only exists on remote tracks
      const castTrack = track as RemoteVideoTrack
      if (!castTrack.receiver) return

      const stats = await unpackVideoReceiverStats(castTrack)
      if (stats) {
        if (this.lastVideoStats) {
          const statsDiff = calculateVideoReceiverDiff(
            stats,
            this.lastVideoStats
          )

          this.applyVideoStats(statsDiff)
        } else {
          this.applyVideoStats(stats)
        }
        this.lastVideoStats = stats
      }
    }, 1000)

    if (this.audioMonitorInterval) {
      clearInterval(this.audioMonitorInterval)
    }
    this.audioMonitorInterval = setInterval(async () => {
      const publications = this.getBreakoutParticipantTrackPublications()
      const audioPublication = publications.find((p) => p.kind === 'audio')
      if (!audioPublication) return
      const track = audioPublication.audioTrack
      if (!track) return
      // The following is the equivalent of the commented out line below
      // if (!(track instanceof RemoteAudioTrack)) return
      // The reason I'm doing is because Typescript exports do not impact the bundles
      // but instanceof checks do. So I'm using a type assertion and then checking for
      // existence of the receiver property, which only exists on remote tracks
      const castTrack = track as RemoteAudioTrack
      if (!castTrack.receiver) return

      const stats = await unpackAudioReceiverStats(castTrack)
      if (stats) {
        if (this.lastAudioStats) {
          const statsDiff = calculateAudioReceiverDiff(
            stats,
            this.lastAudioStats
          )

          this.applyAudioStats(statsDiff)
        } else {
          this.applyAudioStats(stats)
        }
        this.lastAudioStats = stats
      }
    }, 1000)
  }

  applyVideoStats(stats: VideoReceiverStats) {
    this.videoBroadcastFramesDecoded += stats.framesDecoded
    this.videoBroadcastFramesDropped += stats.framesDropped
    this.videoBroadcastFramesReceived += stats.framesReceived
    this.videoBroadcastPacketsDropped += stats.packetsLost
    this.videoBroadcastPacketsReceived += stats.packetsReceived
  }

  applyAudioStats(stats: AudioReceiverStats) {
    this.audioBroadcastPacketsDropped += stats.packetsLost
    this.audioBroadcastPacketsReceived += stats.packetsReceived
  }

  detach() {
    if (this.videoMonitorInterval) {
      clearInterval(this.videoMonitorInterval)
    }
    if (this.audioMonitorInterval) {
      clearInterval(this.audioMonitorInterval)
    }
  }

  flush() {
    // only send stats if we have received any packets
    if (
      this.videoBroadcastPacketsReceived !== 0 &&
      this.audioBroadcastPacketsReceived !== 0
    ) {
      const payload: Record<string, number | string> = {}
      payload['audio_packets_received'] = this.audioBroadcastPacketsReceived
      payload['audio_packets_dropped'] = this.audioBroadcastPacketsDropped
      payload['video_packets_received'] = this.videoBroadcastPacketsReceived
      payload['video_packets_dropped'] = this.videoBroadcastPacketsDropped
      payload['video_frames_received'] = this.videoBroadcastFramesReceived
      payload['video_frames_decoded'] = this.videoBroadcastFramesDecoded
      payload['video_frames_dropped'] = this.videoBroadcastFramesDropped
      payload['slide_index'] = this.meeting.activeSlide
      payload['slide_deck_id'] = this.meeting.slideDeckId
      payload['room_id'] = this.meeting.roomId

      // console.log('broadcast stats', payload)
      this.meeting.repository.logEvent('broadcast_stats', payload)
    } else {
      // console.log('no broadcast stats to send')
    }

    this.audioBroadcastPacketsReceived = 0
    this.audioBroadcastPacketsDropped = 0
    this.videoBroadcastPacketsReceived = 0
    this.videoBroadcastPacketsDropped = 0
    this.videoBroadcastFramesReceived = 0
    this.videoBroadcastFramesDecoded = 0
    this.videoBroadcastFramesDropped = 0
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function unpackVideoReceiverStats(track: any) {
  const stats = await track.receiver?.getStats()
  if (!stats) return
  let receiverStats: VideoReceiverStats | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stats.forEach((v: any) => {
    if (v.type === 'inbound-rtp') {
      receiverStats = {
        framesDecoded: ensureNumber(v.framesDecoded),
        framesDropped: ensureNumber(v.framesDropped),
        framesReceived: ensureNumber(v.framesReceived),
        packetsReceived: ensureNumber(v.packetsReceived),
        packetsLost: ensureNumber(v.packetsLost),
      }
    }
  })
  return receiverStats
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function unpackAudioReceiverStats(track: any) {
  const stats = await track.receiver?.getStats()
  if (!stats) return
  let receiverStats: AudioReceiverStats | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stats.forEach((v: any) => {
    if (v.type === 'inbound-rtp') {
      receiverStats = {
        packetsReceived: ensureNumber(v.packetsReceived),
        packetsLost: ensureNumber(v.packetsLost),
      }
    }
  })
  return receiverStats
}

function calculateVideoReceiverDiff(
  current: VideoReceiverStats,
  previous: VideoReceiverStats
) {
  const diff: VideoReceiverStats = {
    framesDecoded: current.framesDecoded - previous.framesDecoded,
    framesDropped: current.framesDropped - previous.framesDropped,
    framesReceived: current.framesReceived - previous.framesReceived,
    packetsReceived: current.packetsReceived - previous.packetsReceived,
    packetsLost: current.packetsLost - previous.packetsLost,
  }
  return diff
}

function calculateAudioReceiverDiff(
  current: AudioReceiverStats,
  previous: AudioReceiverStats
) {
  const diff: AudioReceiverStats = {
    packetsReceived: current.packetsReceived - previous.packetsReceived,
    packetsLost: current.packetsLost - previous.packetsLost,
  }
  return diff
}

interface ReceiverStats {
  packetsLost: number
  packetsReceived: number
}

interface AudioReceiverStats extends ReceiverStats {}

interface VideoReceiverStats extends ReceiverStats {
  framesDecoded: number
  framesDropped: number
  framesReceived: number
}

function ensureNumber(data: unknown): number {
  if (typeof data === 'number') return data
  if (typeof data === 'string') return parseInt(data, 10)
  return 0
}
