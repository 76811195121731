import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'
import { RubricType } from '../../types'

const rubricTypeSchema = z.nativeEnum(RubricType)

export const schema = z.object({
  rubric: z.string().default(''),
  rubricDescription: z.string().default(''),
  rubricPrompt: z.string().default(''),
  // failing on fake AI
  rubricType: rubricTypeSchema.default(RubricType.blooms),
  rubricLabel0: z.string().optional(),
  rubricLabel1: z.string().optional(),
  slideId: z.string().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    rubric: '',
    rubricDescription: '',
    rubricPrompt: '',
    rubricType: 0,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreSlideRubric = z.infer<typeof schema>
