import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart SettingsProcessingVideo definition
// required String caption,
// required double endTime,
// required String slideDeckId,
// required String slideId,
// required double startTime,
// int? updatedAt,
export const schema = z.object({
  caption: z.string(),
  endTime: z.number(),
  slideDeckId: z.string(),
  slideId: z.string(),
  startTime: z.number(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    caption: '',
    endTime: 0,
    slideDeckId: '',
    slideId: '',
    startTime: 0,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreSlideCaption extends z.infer<typeof schema> {}
