import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required int assignedAt,
// required number assignmentType,
// required int expiresAt,
// required String sectionId,
// required String slideDeckId,
export const schema = z.object({
  assignedAt: firestoreTimestamp,
  assignmentType: z.number(),
  expiresAt: firestoreTimestamp,
  groupingSize: z.number().optional().nullable(),
  groupingSizeMaximum: z.number().optional().nullable(),
  groupingSizeMinimum: z.number().optional().nullable(),
  groupingType: z.number().default(0),
  sectionId: z.string(),
  catalogId: z.string().optional(),
  slideDeckId: z.string(),
  updatedAt: firestoreTimestamp,
})

export const writeSchema = schema.extend({
  assignedAt: firestoreWriteTimestamp,
  expiresAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    assignedAt: { seconds: 0, nanoseconds: 0 },
    assignmentType: 0,
    expiresAt: { seconds: 0, nanoseconds: 0 },
    groupingType: 0,
    sectionId: '',
    slideDeckId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

type FirestoreSectionAssignmentInferredType = z.infer<typeof schema>

export type FirestoreSectionAssignmentWrite = z.infer<typeof writeSchema>

export interface FirestoreSectionAssignment
  extends FirestoreSectionAssignmentInferredType {}
