import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
// required String roomId,
// required String summary,
// String assignmentId,
// String sectionId,
// int? updatedAt,
export const schema = z.object({
  roomId: z.string(),
  summary: z.string(),
  assignmentId: z.string().optional(),
  sectionId: z.string().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    summary: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreRoomStateSummary = z.infer<typeof schema>
