import {
  addDoc,
  and,
  arrayUnion,
  collection,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type PartialWithFieldValue,
  arrayRemove,
} from 'firebase/firestore'
import { SlideDeckState, empty, schema, writeSchema } from './schema'
import type { FirestoreSlideDeck } from './schema'
import type { ObservableModel } from '../../firestore-mobx/model'
import {
  ObservableModelCollection,
  ObservableModelDocument,
} from '../../firestore-mobx/model'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideDeck } from '../../models/SlideDeck'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { z } from 'zod'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'

export interface SlideDeckObservableModel
  extends ObservableModel<FirestoreSlideDeck> {}

export interface SlideDeckObservableModelCollection
  extends ObservableModelCollection<SlideDeck, FirestoreSlideDeck> {}

const converter: FirestoreDataConverter<FirestoreSlideDeck> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSlideDeck>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const fetchFeaturedSlideDecks = async (
  repository: FirebaseRepository
) => {
  const colRef = getColRef(repository.firestore)
  const q = query(colRef, where('slideDeckFeatured', '==', true))
  const docs = await getDocs(q)

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
  })
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSlideDeck> => {
  return collection(firestore, 'slide_deck').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  slideDeckId: string
): DocumentReference<FirestoreSlideDeck, DocumentData> => {
  return doc(getColRef(firestore), slideDeckId)
}

export const buildSlideDeckObservableModelDocument = (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const ref = !slideDeckId
    ? undefined
    : getDocRef(repository.firestore, slideDeckId)

  return new ObservableModelDocument({
    ref,
    repository,
    model: SlideDeck,
    empty: empty,
  })
}

export const buildSlideDeckObservableModelCollection = (
  repository: FirebaseRepository,
  params?: {
    slideDeckIds: string[]
  }
): SlideDeckObservableModelCollection => {
  const collection = new ObservableModelCollection({
    repository,
    model: SlideDeck,
    empty: empty,
  })

  if (params) configureSlideDeckObservableModelCollection(collection, params)

  return collection
}

export const configureSlideDeckObservableModelCollection = (
  collection: SlideDeckObservableModelCollection,
  params: {
    slideDeckIds: string[]
  }
) => {
  if (params.slideDeckIds.length === 0) {
    collection.attachTo(undefined)
    collection.query = undefined
  } else {
    collection.attachTo(getColRef(collection.repository.firestore))
    collection.query = (ref) => {
      return query(ref, where(documentId(), 'in', params.slideDeckIds))
    }
  }
}

export const getSlideDeck = (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, slideDeckId)

  return modelItemStream(repository, docRef, SlideDeck)
}

export const fetchSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)

  const doc = await getDoc(docRef)

  if (!doc.exists()) throw new Error(`Slide deck ${slideDeckId} not found`)

  return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
}

export const fetchSlideDecks = async (repository: FirebaseRepository) => {
  const docRef = getColRef(repository.firestore)

  const docs = await getDocs(docRef)

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
  })
}

export const getSlideDecksForCatalog = (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore)

  const catalogIdsPredicate = where('catalogIds', 'array-contains', catalogId)
  const statePredicate = where('slideDeckState', '>=', SlideDeckState.published)

  const q = query(ref, and(catalogIdsPredicate, statePredicate))

  return modelListStream(repository, q, SlideDeck)
}

export const fetchSlideDeckCountForCatalog = async (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore)

  const catalogIdsPredicate = where('catalogIds', 'array-contains', catalogId)
  const statePredicate = where('slideDeckState', '>=', SlideDeckState.published)

  const q = query(ref, and(catalogIdsPredicate, statePredicate))

  const snapshot = await getCountFromServer(q)

  return snapshot.data().count
}

/**
 * Get List of SlideDecks from Firestore
 * query on slideDeckState > 0 to avoid
 * soft deleted and initializing slide decks
 */
export const getSlideDecks = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('slideDeckState', '>=', SlideDeckState.draft))
  return modelListStream(repository, q, SlideDeck)
}

/**
 * Create a new slide deck in Firestore
 */
export const createSlideDeck = async (
  repository: FirebaseRepository
): Promise<string> => {
  const colRef = getColRef(repository.firestore)
  const ref = await addDoc(colRef, {
    catalogIds: [],
    slideDeckState: SlideDeckState.draft,
    slideDeckFree: false,
    slideDeckName: '',
    slideDeckPrice: 1,
    slideDeckVersion: '0.0.0', // initial value
    slideDeckTypeId: '',
    slideDeckFeatured: false,
    slideDeckDisciplines: [],
    slideDeckIndustries: [],
    slideDeckLearningObjectives: [],
    updatedAt: serverTimestamp(),
  })

  return ref.id
}

/// add a [Catalog] id entry to a [SlideDeck]
/// slide decks with a catalog id should always have published state
export const addCatalogToSlideDeck = async (
  repository: FirebaseRepository,
  { catalogId, slideDeckId }: { catalogId: string; slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)

  return setDoc(
    docRef,
    {
      catalogIds: arrayUnion(catalogId),
      slideDeckState: SlideDeckState.published,
    },
    { merge: true }
  )
}

export const removeCatalogFromSlideDeck = async (
  repository: FirebaseRepository,
  { catalogId, slideDeckId }: { catalogId: string; slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)

  return setDoc(
    docRef,
    {
      catalogIds: arrayRemove(catalogId),
    },
    { merge: true }
  )
}

export type ExperienceDetailsForUpload = {
  slideDeckDescription: string
  slideDeckDisciplines: string[]
  slideDeckFeatured: boolean
  slideDeckGoogleTemplateURL: string
  slideDeckIndustries: string[]
  slideDeckLearningObjectives: string[]
  slideDeckName: string
  slideDeckPrice: number
  slideDeckTeaser: string
  slideDeckVersion: string
  slideDeckImageURL: string
}

export const saveSlideDeckForm = async (
  repository: FirebaseRepository,
  slideDeckId: string,
  payload: Partial<ExperienceDetailsForUpload>
) => {
  const data = {
    ...payload,
    slideDeckFree: payload.slideDeckPrice === 0,
    updatedAt: serverTimestamp(),
  }

  const docRef = getDocRef(repository.firestore, slideDeckId)

  return setDoc(docRef, data, { merge: true })
}

export const getSlideDecksOfType = (
  repository: FirebaseRepository,
  { slideDeckTypeId }: { slideDeckTypeId: string }
) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('slideDeckTypeId', '==', slideDeckTypeId))
  return modelListStream(repository, q, SlideDeck)
}

/**
 * Creates a shallow copy of a [SlideDeck] with state set to -1
 * to fire the deep copy trigger. The promise resolves when the deep copy
 * finishes and sets the state to 0 (draft)
 */
export const deepCopySlideDeck = async (
  repository: FirebaseRepository,
  {
    slideDeck,
    newVersionName,
  }: { slideDeck: SlideDeck; newVersionName: string }
) => {
  // alias for slideDeckData (less typing)
  const d = slideDeck.data
  const writeData: z.infer<typeof writeSchema> = {
    catalogIds: [],
    slideDeckDescription: d.slideDeckDescription,
    slideDeckFeatured: false,
    slideDeckFree: d.slideDeckFree,
    slideDeckName: d.slideDeckName,
    slideDeckPrice: d.slideDeckPrice,
    slideDeckTeaser: d.slideDeckTeaser,
    slideDeckParentId: slideDeck.id,
    slideDeckTypeId: d.slideDeckTypeId,
    // different from dart
    // industries/disciplines/learning objectives were not copied
    // but that was probably an oversight
    slideDeckDisciplines: d.slideDeckDisciplines,
    slideDeckIndustries: d.slideDeckIndustries,
    slideDeckLearningObjectives: d.slideDeckLearningObjectives,
    // slide deck state uninitialized invokes the deep copy trigger
    slideDeckState: SlideDeckState.uninitialized,
    slideDeckVersion: newVersionName,
    updatedAt: serverTimestamp(),
  }
  const { id } = await addDoc(getColRef(repository.firestore), writeData)
  const docRef = getDocRef(repository.firestore, id)

  // stream the newly created slide deck and return when
  // the slide deck state moves from uninitialized to draft
  return await modelItemStream(repository, docRef, SlideDeck).firstWhere(
    (d) => d.slideDeckState === SlideDeckState.draft
  )
}

// Upload a slide deck image
export const uploadSlideDeckImage = async (
  repository: FirebaseRepository,
  { slideDeckId, file }: { slideDeckId: string; file: File }
) => {
  const mimeType = file.type
  if (!mimeType.startsWith('image/')) {
    throw new Error('Invalid image type')
  }

  const storageRef = ref(repository.storage, `slide_deck/images/${slideDeckId}`)
  await uploadBytes(storageRef, file, {
    contentType: mimeType,
  })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  const docRef = getDocRef(repository.firestore, slideDeckId)

  setDoc(docRef, { slideDeckImageURL: urlWithoutToken }, { merge: true })
  return urlWithoutToken
}

export const deleteSlideDeckImage = async (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const storageRef = ref(repository.storage, `slide_deck/images/${slideDeckId}`)
  await safeDeleteStorageObject(storageRef)

  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDoc(docRef, { slideDeckImageURL: '' }, { merge: true })
}

export const updateSlideDeckFeatured = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideDeckFeatured,
  }: { slideDeckId: string; slideDeckFeatured: boolean }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDoc(docRef, { slideDeckFeatured }, { merge: true })
}

export const deleteSlideDeck = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
  }: {
    slideDeckId: string
  }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDoc(
    docRef,
    {
      slideDeckState: SlideDeckState.deleted,
      slideDeckFeatured: false,
      updatedAt: serverTimestamp(),
    },
    { merge: true }
  )
}
