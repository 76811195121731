import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { FetchedCollection } from '../../firestore-mobx'
import { ObservableCollection } from '../../firestore-mobx/collection'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreUserProfileToken } from './schema'
import { schema, writeSchema } from './schema'
import { modelListStream } from '../../firestore-mobx/stream'
import { UserProfileToken } from '../../models/UserProfileToken'
import type { StreamInterface } from 'tricklejs/dist/types'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserProfileToken> = {
  toFirestore: (user: PartialWithFieldValue<FirestoreUserProfileToken>) => {
    writeSchema.partial().parse(user)

    return user
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  userId: string
): CollectionReference<FirestoreUserProfileToken, DocumentData> => {
  return collection(firestore, 'user_profile', userId, 'token').withConverter(
    converter
  )
}

export const buildFirestoreUserProfileTokenObservableCollection = (
  repository: FirebaseRepository,
  userId: string
): ObservableCollection<FirestoreUserProfileToken> => {
  return new ObservableCollection(getColRef(repository.firestore, userId))
}

export const buildFirestoreUserProfileTokenFetchedCollection = (
  firestore: Firestore,
  userId: string
): FetchedCollection<FirestoreUserProfileToken> => {
  return new FetchedCollection(getColRef(firestore, userId))
}

export const getUserProfileTokens = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<UserProfileToken[]> => {
  const ref = getColRef(repository.firestore, params.userId)

  return modelListStream(repository, ref, UserProfileToken)
}

export const createUserProfileToken = async (
  repository: FirebaseRepository,
  params: {
    userId: string
    tokenQuantity: number
  }
): Promise<void> => {
  if (params.tokenQuantity <= 0) {
    throw new Error('Token quantity must be greater than 0')
  }

  const { userId, tokenQuantity } = params
  const ref = getColRef(repository.firestore, userId)

  await addDoc(ref, {
    consumed: false,
    createdAt: serverTimestamp(),
    creatorUserId: repository.uid,
    price: 0,
    paymentId: null,
    purchaseType: 'grant',
    tokenQuantity,
    updatedAt: serverTimestamp(),
    userId,
  })
}
