import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import {
  addSectionToCatalog,
  deleteCatalog,
  getAllAppUserWithCatalogAccess,
  getCatalog,
  removeSectionFromCatalog,
  updateCatalog,
} from '../firestore/Catalog'
import { InvitationType } from '../types'
import type { StaticModelCollection } from '../types'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import {
  addCatalogToSlideDeck,
  getSlideDecks,
  getSlideDecksForCatalog,
} from '../firestore/SlideDeck'
import { SlideDeckGroup } from '../stores/SlideDeckGroup'
import type { AppUser } from '../stores/AppUser'
import { fetchAllAppUsersByRole } from '../firestore/PublicUser'
import {
  addCatalogToUserProfile,
  removeCatalogFromUserProfile,
} from '../firestore/UserProfile'
import { Section } from '../models/Section'
import { getSectionsStreamForInstructor } from '../firestore/Section'
import { createInvitationInstructorWithCatalog } from '../firestore/Invitation'
import { fetchSettingsProtectedCatalogIds } from '../firestore/SettingsCatalogs'

export enum AdminCatalogTab {
  experiences = 'experiences',
  details = 'details',
}

export class AdminCatalogCubit extends Cubit {
  repository: FirebaseRepository

  @observable
  tab: AdminCatalogTab = AdminCatalogTab.experiences

  catalog: Catalog
  catalogId: string

  slideDecks: StaticModelCollection<SlideDeck>
  allSlideDecks: StaticModelCollection<SlideDeck>
  sections: StaticModelCollection<Section>
  instructors = observable.array<AppUser>()
  protected = observable.array<string>()

  authorizedUsers = observable.array<AppUser>()

  @observable showHidden = false

  constructor(repository: FirebaseRepository, catalogId: string) {
    super()
    makeObservable(this)
    this.catalogId = catalogId
    this.repository = repository
    this.catalog = Catalog.empty(repository)
    this.slideDecks = SlideDeck.emptyCollection(repository)
    this.allSlideDecks = SlideDeck.emptyCollection(repository)
    this.sections = Section.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getCatalog(this.repository, {
        catalogId: this.catalogId,
      }),
      (catalog) => {
        this.catalog.replaceModel(catalog)
      }
    )
    this.addStream(
      getSlideDecksForCatalog(this.repository, {
        catalogId: this.catalogId,
      }),
      (slideDecks) => {
        this.slideDecks.replaceModels(slideDecks)
      }
    )
    this.addStream(getSlideDecks(this.repository), (slideDecks) => {
      this.allSlideDecks.replaceModels(slideDecks)
    })
    this.addStream(
      getAllAppUserWithCatalogAccess(this.repository, this.catalogId),
      (authorizedUsers) => {
        this.authorizedUsers.replace(authorizedUsers)
      }
    )
    fetchAllAppUsersByRole(this.repository, 'instructor').then(
      (instructors) => {
        this.instructors.replace(instructors)
      }
    )
    this.addStream(
      getSectionsStreamForInstructor(this.repository, {
        instructorUserId: this.repository.uid,
      }),
      (sections) => {
        this.sections.replaceModels(sections)
      }
    )

    fetchSettingsProtectedCatalogIds(this.repository).then((protectedIds) => {
      runInAction(() => {
        this.protected.replace(protectedIds)
      })
    })
  }

  @computed
  get addableInstructors() {
    return this.instructors.filter((instructor) => {
      return !this.authorizedUsers.find((user) => {
        return user.uid === instructor.uid
      })
    })
  }

  @computed
  get slideDecksGroups() {
    const grouped = new Map<string, SlideDeckGroup>()

    const filtered = this.showHidden
      ? this.slideDecks.models
      : this.slideDecks.models.filter((slideDeck) => {
          return slideDeck.data.slideDeckState !== SlideDeckState.hidden
        })

    filtered.forEach((slideDeck) => {
      const parentId = slideDeck.data.slideDeckTypeId || slideDeck.id
      let group = grouped.get(parentId)

      if (!group) {
        group = new SlideDeckGroup(parentId)
        grouped.set(parentId, group)
      }

      group.addSlideDeck(slideDeck)
    })

    return Array.from(grouped.values()).sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime()
    })
  }

  @computed
  get allSlideDecksGroups() {
    const grouped = new Map<string, SlideDeckGroup>()

    const existingSlideDeckIds = this.slideDecks.models.map(
      (slideDeck) => slideDeck.id
    )

    this.allSlideDecks.models.forEach((slideDeck) => {
      if (existingSlideDeckIds.includes(slideDeck.id)) return
      const parentId = slideDeck.data.slideDeckTypeId || slideDeck.id
      let group = grouped.get(parentId)

      if (!group) {
        group = new SlideDeckGroup(parentId)
        grouped.set(parentId, group)
      }

      group.addSlideDeck(slideDeck)
    })

    return Array.from(grouped.values()).sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime()
    })
  }

  @computed
  get isCatalogProtected() {
    return this.protected.includes(this.catalogId)
  }

  @action
  changeTab(tab: AdminCatalogTab): void {
    this.tab = tab
  }

  updateCatalog(params: {
    catalogName: string
    catalogDescription: string
  }): void {
    updateCatalog(this.repository, this.catalogId, params)
  }

  deleteCatalog(): void {
    // guard against deleting protected catalogs
    if (this.isCatalogProtected) return

    deleteCatalog(this.repository, this.catalogId)
  }

  addUser(userId: string) {
    addCatalogToUserProfile(this.repository, {
      userId,
      catalogId: this.catalogId,
    })
  }

  removeUser(userId: string) {
    removeCatalogFromUserProfile(this.repository, {
      userId,
      catalogId: this.catalogId,
    })
  }

  addSection(sectionId: string) {
    addSectionToCatalog(this.repository, {
      catalogId: this.catalogId,
      sectionId,
    })
  }

  removeSection(sectionId: string) {
    removeSectionFromCatalog(this.repository, {
      catalogId: this.catalogId,
      sectionId,
    })
  }

  addSlideDeck(slideDeckId: string) {
    addCatalogToSlideDeck(this.repository, {
      catalogId: this.catalogId,
      slideDeckId,
    })
  }

  async createInvitation({ type }: { type: InvitationType }) {
    const doc = await createInvitationInstructorWithCatalog(this.repository, {
      catalogId: this.catalogId,
      oneTime: type === InvitationType.oneTime,
    })

    return doc.id
  }
}
