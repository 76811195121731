import { FieldValue, Timestamp } from 'firebase/firestore'
import z from 'zod'

export const firestoreTimestamp = z
  .object({
    seconds: z.number(),
    nanoseconds: z.number(),
  })
  .transform((val) => new Timestamp(val.seconds, val.nanoseconds).toDate())

export const firestoreWriteTimestamp = z
  .date()
  .or(
    z.object({
      _methodName: z.enum(['serverTimestamp']),
    })
  )
  .or(z.custom((val) => val instanceof FieldValue, { message: 'FieldValue' }))
  .transform((val) => {
    return val as FieldValue | Date
  })
