import { z } from 'zod'
import { firestoreTimestamp } from '../shared/schema'

export const slideDeckAuthorSchema = z.object({
  authorBio: z.string().default(''),
  authorImageURL: z.string().default('').optional(),
  authorInstitution: z.string().default(''),
  authorName: z.string().default(''),
  authorTitle: z.string().default(''),
  order: z.number().optional(),
  updatedAt: firestoreTimestamp,
})

export const emptySlideDeckAuthor = slideDeckAuthorSchema.parse({
  bio: '',
  imageURL: '',
  institution: '',
  name: '',
  title: '',
  updatedAt: { seconds: 0, nanoseconds: 0 },
  order: 0,
})

export type FirestoreSlideDeckAuthor = z.infer<typeof slideDeckAuthorSchema>
