import type { User } from 'firebase/auth'
import type { Firestore } from 'firebase/firestore'
import { computed } from 'mobx'
import { Cubit } from '../cubits/core'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { getUserProfilePurchases } from '../firestore/UserProfilePurchase'
import { getUserProfileTokens } from '../firestore/UserProfileToken'
import type { BreakoutUser } from '../models/BreakoutUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { UserProfilePurchase } from '../models/UserProfilePurchase'
import { UserProfileToken } from '../models/UserProfileToken'

// TODO: move to stores
export class UserProfileTokens extends Cubit {
  firestore: Firestore
  repository: FirebaseRepository
  user: BreakoutUser | User

  _tokensCollection: StaticModelCollection<UserProfileToken>
  _purchaseCollection: StaticModelCollection<UserProfilePurchase>

  constructor(repository: FirebaseRepository, user: BreakoutUser | User) {
    super()
    this.user = user
    this.repository = repository
    this.firestore = repository.firestore
    this._tokensCollection = UserProfileToken.emptyCollection(repository)
    this._purchaseCollection = UserProfilePurchase.emptyCollection(repository)
  }

  startTokenStreamIfNotStarted() {
    const name = 'tokens'
    // only start once
    if (this.hasStream(name)) return

    this.addStream(
      getUserProfileTokens(this.repository, { userId: this.user.uid }),
      (tokens) => {
        this._tokensCollection.replaceModels(tokens)
      },
      { name }
    )
  }

  startPurchasesStreamIfNotStarted() {
    const name = 'purchases'
    // only start once
    if (this.hasStream(name)) return

    this.addStream(
      getUserProfilePurchases(this.repository, { userId: this.user.uid }),
      (purchases) => {
        this._purchaseCollection.replaceModels(purchases)
      },
      { name }
    )
  }

  get tokensCollection() {
    this.startTokenStreamIfNotStarted()
    return this._tokensCollection
  }

  get purchaseCollection() {
    this.startPurchasesStreamIfNotStarted()
    return this._purchaseCollection
  }

  @computed
  get isLoading() {
    return this.tokensCollection.isLoading || this.purchaseCollection.isLoading
  }

  @computed
  get availableTokens() {
    let totalAvailable = 0
    for (const token of this.tokensCollection.documents) {
      if (token.data.consumed === true) continue
      totalAvailable +=
        token.data.tokenQuantity - (token.data.consumedCount || 0)
    }
    return totalAvailable
  }

  @computed
  get tokensAvailable() {
    return this.tokens
      .filter((token) => token.data.consumed === false)
      .reduce((acc, token) => acc + token.data.tokenQuantity, 0)
  }

  @computed
  get tokensConsumed() {
    return this.tokens
      .filter((token) => token.data.consumed === true)
      .reduce((acc, token) => acc + token.data.tokenQuantity, 0)
  }

  get tokens() {
    return this.tokensCollection.models
  }

  get purchases() {
    return this.purchaseCollection.models
  }
}
