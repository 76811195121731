import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
export const schema = z.object({
  catalogName: z.string(),
  catalogDescription: z.string(),
  catalogSharedSectionIds: z
    .array(z.string())
    .default([])
    .optional()
    .transform((val) => val ?? []),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    catalogName: '',
    catalogDescription: '',
    catalogSharedSectionIds: [],
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreCatalog extends z.infer<typeof schema> {}
