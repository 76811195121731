import { type Room } from 'livekit-client'
import { BroadcastState, type TranscriptMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'
import { BroadcastMonitor } from './BroadcastMonitor'
import { TranscriptController } from './TranscriptController'

/**
 * The MeetingLivekitController class is responsible for communicating with Livekit
 * and updating the Meeting model with the relavant data.
 *
 * The idea is to break up the Meeting class into smaller, more manageable pieces.
 */
export class MeetingLivekitController {
  unsubscribers: (() => void)[] = []
  transcriptController: TranscriptController
  // import { EventEmitter } from 'events'

  constructor(protected meeting: MeetingCubit) {
    this.transcriptController = new TranscriptController(meeting)
  }

  initialize() {
    this.setupBroadcastMonitor()
  }

  dispose() {
    for (const unsub of this.unsubscribers) {
      unsub()
    }
    this.livekitRoom?.removeAllListeners()
    this.broadcastMonitor?.detach()
  }

  onSlideChange() {
    this.setupBroadcastMonitor()
  }

  lastMessage: string = ''
  sendStreamStatus(status: string, position: number, duration: number) {
    if (!this.livekitRoom) return

    const slideId = this.meeting.currentSlide?.id

    if (!slideId) return

    const message = JSON.stringify({
      type: 6,
      status,
      duration: Math.round(duration),
      position: Math.round(position),
      identity: this.currentUser.uid,
      userId: this.currentUser.uid,
      slideId,
    })

    this.meeting.updateSlideStreamStatus(
      slideId,
      this.currentUser.uid,
      status,
      position,
      duration
    )

    if (this.lastMessage === message) return

    this.lastMessage = message

    const participantArray = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const groupLeaders = participantArray.filter((p) =>
      this.meeting.groupLeaderUserIds.includes(p.identity)
    )

    if (groupLeaders.length === 0) return

    this.livekitRoom.localParticipant?.publishData(
      new TextEncoder().encode(message),
      {
        reliable: true,
        destinationIdentities: groupLeaders.map((p) => p.identity),
      }
    )
  }

  sendBroadcastMessage(message: string) {
    if (this.livekitRoom) {
      const participantArray = Array.from(
        this.livekitRoom.remoteParticipants.values()
      )
      const breakoutParticipant = participantArray.find(
        (p) => p.identity === 'Breakout Learning'
      )
      if (!breakoutParticipant) return

      if (message === 'pause') {
        this.meeting.setBroadcastState(BroadcastState.paused)
      } else if (message === 'stop') {
        this.meeting.setBroadcastState(BroadcastState.stopped)
      } else if (message === 'play') {
        this.meeting.setBroadcastState(BroadcastState.playing)
      }

      this.livekitRoom.localParticipant?.publishData(
        new TextEncoder().encode(message),
        {
          reliable: true,
          destinationIdentities: [breakoutParticipant.identity],
        }
      )
    }
  }

  updateMetadata() {
    if (!this.livekitRoom) return
    if (!this.livekitRoom.localParticipant) return
    const currentMetadata = this.livekitRoom.localParticipant.metadata || '{}'
    const parsed = JSON.parse(currentMetadata)
    parsed['info'] = 'test'
    const serialized = JSON.stringify(parsed)
    this.livekitRoom.localParticipant.setMetadata(serialized)
  }

  seekBroadcast(position: number) {
    if (this.livekitRoom) {
      this.meeting.setBroadcastPosition(position + 3)
      this.sendBroadcastMessage(`seek|${position + 3}`)
    }
  }

  setupLivekitListeners() {
    const room = this.livekitRoom
    if (!room) return

    room.on('trackMuted', (publication) => {
      this.meeting.logEvent('room_track_muted', {
        track_kind: publication.kind,
      })
    })
    room.on('trackUnmuted', (publication) => {
      this.meeting.logEvent('room_track_unmuted', {
        track_kind: publication.kind,
      })
    })
    room.on('audioPlaybackChanged', (playback: boolean) => {
      console.log('audioPlaybackChanged', playback)
    })
    room.on('activeSpeakersChanged', (speakers) => {
      const sorted = speakers.sort((a, b) =>
        b.audioLevel > a.audioLevel ? 1 : -1
      )
      const first = sorted[0]

      this.meeting.updateActiveSpeaker(first?.identity || null)
    })
    room.on('dataReceived', (data) => {
      const decoded = new TextDecoder().decode(data)
      const parsed = JSON.parse(decoded)
      if (parsed) {
        if (parsed.type === 0) {
          const status = parsed.status as number
          if (status === 2)
            this.meeting.setBroadcastState(BroadcastState.playing)
          if (status === 3)
            this.meeting.setBroadcastState(BroadcastState.paused)
          if (status === 4)
            this.meeting.setBroadcastState(BroadcastState.stopped)
        } else if (parsed.type === 1) {
          const duration = parsed.duration as number
          this.meeting.setBroadcastDuration(duration)
          // duration
        } else if (parsed.type === 2) {
          const position = parsed.position as number
          const duration = parsed.duration as number

          this.meeting.setBroadcastState(BroadcastState.playing)
          this.meeting.setBroadcastPositionAndDuration(position, duration)
          // position
        } else if (parsed.type === 3) {
          const message = parsed as TranscriptMessage
          this.transcriptController.handleMessage(message)
          // transcript
        } else if (parsed.type === 4) {
          // unknown
        } else if (parsed.type === 6) {
          this.meeting.updateSlideStreamStatus(
            parsed.slideId,
            parsed.userId,
            parsed.status,
            parsed.position,
            parsed.duration
          )
        } else if (parsed.type === 7) {
          this.meeting.localCommands.emit('pauseVideo')
        }
      }
    })
    room.on('roomMetadataChanged', (metadata) => {
      console.log('roomMetadataChanged', metadata)
    })
    room.on('participantMetadataChanged', (participant, metadata) => {
      console.log('participantMetadataChanged', participant, metadata)
    })
    room.on('participantConnected', () => {
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })
    room.on('participantDisconnected', () => {
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })
    room.on('disconnected', () => {
      this.meeting.logEvent('room_disconnected')
    })
    room.on('reconnected', () => {
      this.meeting.logEvent('room_reconnected')
    })
    room.on('connected', () => {
      this.meeting.logEvent('room_connected')
      this.updateParticipantIds()
      this.setupBroadcastMonitor()

      const participants = Array.from(room.remoteParticipants.values())

      const breakoutParticipant = participants.find(
        (p) => p.identity === 'Breakout Learning'
      )

      if (breakoutParticipant) {
        // assume we are initialized, an event from the broadcast will update this
        this.meeting.setBroadcastState(BroadcastState.initialized)
      }
    })
  }

  updateParticipantIds() {
    if (!this.livekitRoom) return
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const userIds = participants.map((p) => p.identity)

    this.meeting.updateParticipantIds(userIds)
  }

  mute() {
    if (!this.livekitRoom) return
    this.livekitRoom?.localParticipant.setMicrophoneEnabled(false)
  }

  toggleAudio() {
    if (!this.livekitRoom) return
    const currentState = this.livekitRoom.localParticipant.isMicrophoneEnabled
    this.livekitRoom?.localParticipant.setMicrophoneEnabled(!currentState)
  }

  unmute() {
    if (!this.livekitRoom) return
    this.livekitRoom?.localParticipant.setMicrophoneEnabled(true)
  }

  isAudioEnabled() {
    return this.livekitRoom?.localParticipant.isMicrophoneEnabled
  }

  toggleVideo() {
    if (!this.livekitRoom) return
    const currentState = this.livekitRoom.localParticipant.isCameraEnabled
    this.livekitRoom?.localParticipant.setCameraEnabled(!currentState)
  }

  toggleScreenShare() {
    if (!this.livekitRoom) return
    const currentState = this.livekitRoom.localParticipant.isScreenShareEnabled
    this.livekitRoom?.localParticipant.setScreenShareEnabled(!currentState)
  }

  disableVideo() {
    if (!this.livekitRoom) return
    this.livekitRoom?.localParticipant.setCameraEnabled(false)
  }

  enableVideo() {
    if (!this.livekitRoom) return
    this.livekitRoom?.localParticipant.setCameraEnabled(true)
  }

  isVideoEnabled() {
    return this.livekitRoom?.localParticipant.isCameraEnabled
  }

  toggleVideoForParticipant(participantId: string) {
    const participant = this.livekitRoom?.remoteParticipants.get(participantId)
    if (!participant) return

    for (const publication of participant.trackPublications.values()) {
      if (publication.kind.toString() === 'video') {
        publication.setSubscribed(!publication.isSubscribed)
      }
    }
  }

  get livekitRoom(): Room | null {
    return this.meeting.livekitRoom
  }

  get currentUser() {
    return this.meeting.currentUser
  }

  broadcastMonitor: BroadcastMonitor | null = null

  setupBroadcastMonitor() {
    if (!this.livekitRoom) {
      return
    }
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )

    const breakoutParticipant = participants.find(
      (p) => p.identity === 'Breakout Learning'
    )

    // if the slide changed and we have a running broadastMonitor,
    // we need to flush it and start a new one
    if (
      breakoutParticipant &&
      this.broadcastMonitor &&
      this.broadcastMonitor.monitoredSlide !== this.meeting.activeSlide
    ) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    }
    if (breakoutParticipant && !this.broadcastMonitor) {
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    } else if (!breakoutParticipant && this.broadcastMonitor) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = null
    }
  }
}
