import { firestoreTimestamp } from '../../firestore/shared/schema'
import { z } from 'zod'

// Define the schema for SlideDeckMaterialType
export enum SlideDeckMaterialType {
  url = 0,
  pdf = 1,
  mp3 = 2,
  webm = 3,
  featuredLarge = 4,
  jpg = 5,
  mp4 = 6,
}

// Define the schema for SlideDeckMaterialWorkType
export enum SlideDeckMaterialWorkType {
  neither = 0,
  preWork = 1,
  postWork = 2,
}

const SlideDeckMaterialTypeSchema = z.nativeEnum(SlideDeckMaterialType)
const SlideDeckMaterialWorkTypeSchema = z.nativeEnum(SlideDeckMaterialWorkType)

// Define the main SlideDeckMaterial schema
export const schema = z.object({
  materialName: z.string().default(''),
  materialLink: z.string().default(''),
  materialDescription: z.string().optional(),
  materialOrder: z.number().optional(),
  materialType: SlideDeckMaterialTypeSchema,
  updatedAt: firestoreTimestamp,
  viewableByInstructor: z.boolean().default(true),
  viewableByPublic: z.boolean().default(false),
  viewableByStudent: z.boolean().default(true),
  materialWorkType: SlideDeckMaterialWorkTypeSchema.default(
    SlideDeckMaterialWorkType.neither
  ),
  // only on podcasts
  imageUrl: z.string().url().optional(),
})

export const empty = schema.parse({
  materialDescription: '',
  materialOrder: 0,
  materialType: SlideDeckMaterialType.url,
  updatedAt: { seconds: 0, nanoseconds: 0 },
})

export type FirestoreSlideDeckMaterial = z.infer<typeof schema>
