import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required String id,
// required String roomId,
// required int score,
// required String userId,
// String? comment,
// int? updatedAt,
// bool? hubspotWrite,
export const schema = z.object({
  roomId: z.string(),
  score: z.number(),
  userId: z.string(),
  comment: z.string().optional(),
  updatedAt: firestoreTimestamp,
  hubspotWrite: z.boolean().optional(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    score: -1,
    userId: '',
    comment: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
    hubspotWrite: false,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreRoomStateFeedback = z.infer<typeof schema>
