import { roomStateAddHiddenUser } from '../firestore/RoomState'
import { fetchUserProfileRoomToken } from '../firestore/UserProfileRoomToken'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { RoomState } from '../models/RoomState'

export async function observeRoom(
  repository: FirebaseRepository,
  roomState: RoomState
) {
  const isUserInRoom =
    roomState.data.hiddenUserIds?.includes(repository.uid) ||
    roomState.data.userIds.includes(repository.uid) ||
    roomState.data.groupLeaderUserIds.includes(repository.uid)

  if (isUserInRoom) return Promise.resolve(true)

  await roomStateAddHiddenUser(repository.firestore, {
    roomId: roomState.id,
    userId: repository.uid,
  })

  let token: string | undefined

  // try to get token 4 times, with 500ms interval
  // if we fail to get token within 2 seconds, return false
  for (let i = 0; i < 4; i++) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    const userProfileRoomToken = await fetchUserProfileRoomToken(repository, {
      userId: repository.uid,
      roomId: roomState.id,
    })
    token = userProfileRoomToken.data.token
    if (token) break
  }

  if (!token) {
    return false
  }

  return true
}
