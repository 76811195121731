import { computed, makeObservable } from 'mobx'

import type { DateTime } from 'luxon'
import {
  fetchInstructorSections,
  fetchSectionsForInstructors,
} from '../firestore/Section'
import { createSectionAssignment } from '../firestore/SectionAssignment'
import { getSlideDeck } from '../firestore/SlideDeck'
import { fetchSlideDeckMaterialsForInstructor } from '../firestore/SlideDeckMaterial'
import { fetchSlideQuestions } from '../firestore/SlideQuestion'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type {
  AssignmentGroupingType,
  AssignmentType,
} from '../models/SectionAssignment'
import { SlideDeck } from '../models/SlideDeck'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideQuestion } from '../models/SlideQuestion'
import type { StaticModelCollection } from '../types'
import { Cubit } from './core'
import { captureException } from '@sentry/core'
import { fetchTAInstructorIDs } from '../firestore/UserProfile'
import { fetchCatalogsAccessibleByUsers } from '../firestore/Catalog'
import { type Section } from '../models/Section'
import { fetchSlides } from '../firestore/Slide'
import { SlideModel } from '../models/SlideModel'

export class InstructorSlideDeckCubit extends Cubit {
  repository: FirebaseRepository

  slideDeckId: string

  slideDeck: SlideDeck
  slides: StaticModelCollection<SlideModel>
  questions: StaticModelCollection<SlideQuestion>
  materials: StaticModelCollection<SlideDeckMaterial>

  constructor(repository: FirebaseRepository, slideDeckId: string) {
    super()
    this.slideDeckId = slideDeckId
    makeObservable(this)

    this.repository = repository
    this.slideDeck = SlideDeck.empty(repository)
    this.slides = SlideModel.emptyCollection(repository)
    this.questions = SlideQuestion.emptyCollection(repository)
    this.materials = SlideDeckMaterial.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSlideDeck(this.repository, { slideDeckId: this.slideDeckId }),
      (slideDeck) => {
        this.slideDeck.replaceModel(slideDeck)
      }
    )
    this.fetchQuestions()
    this.fetchMaterials()
    this.fetchSlides()
  }

  @computed
  get questionsSorted() {
    const slideIds = this.slidesSorted.map((slide) => slide.id)
    return this.questions.models.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId ?? '')
      const bIndex = slideIds.indexOf(b.data.slideId ?? '')
      return aIndex - bIndex || a.data.question.localeCompare(b.data.question)
    })
  }

  @computed
  get slidesSorted() {
    return this.slides.models.sort(
      (a, b) => a.data.slideOrder - b.data.slideOrder
    )
  }

  sortSections = (sections: Section[]) => {
    return sections.sort((a: Section, b: Section) => {
      if (a.data.sectionState === b.data.sectionState) {
        if (
          a.data.updatedAt.getMilliseconds() ===
          b.data.updatedAt.getMilliseconds()
        ) {
          if (a.data.className !== b.data.className) {
            return a.data.className.localeCompare(b.data.className)
          } else {
            return a.data.sectionName.localeCompare(b.data.sectionName)
          }
        }
        return a.data.updatedAt!.getTime() - b.data.updatedAt!.getTime()
      }
      return a.data.sectionState - b.data.sectionState
    })
  }

  async fetchSlides() {
    try {
      const slides = await fetchSlides(this.repository, {
        slideDeckId: this.slideDeckId,
      })
      this.slides.replaceModels(slides)
    } catch (error) {
      captureException(error)
    }
  }

  async fetchMaterials() {
    try {
      const data = await fetchSlideDeckMaterialsForInstructor(this.repository, {
        slideDeckId: this.slideDeckId,
      })
      this.materials.replaceModels(data)
    } catch (error) {
      captureException(error)
    }
  }

  async fetchQuestions() {
    const data = await fetchSlideQuestions(this.repository, {
      slideDeckId: this.slideDeckId,
    })

    this.questions.replaceModels(data)
  }

  async fetchSections(
    catalogId: string,
    role: string,
    instructorUserId?: string
  ) {
    if (role !== 'ta') {
      return fetchInstructorSections(this.repository, { instructorUserId })
    }

    /* TA only logic */

    // get instructors which can access the catalog
    const instructorIds = await fetchTAInstructorIDs(this.repository)

    // get catalogs accessible by the instructors
    const catalogsAccessibleByInstructorId =
      await fetchCatalogsAccessibleByUsers(this.repository, {
        userIds: instructorIds,
      })

    // prune instructors which can not access the current catalog
    const instructorsWithCatalogAccess = instructorIds.filter(
      (instructorId) => {
        const instructorCatalogs =
          instructorId in catalogsAccessibleByInstructorId
            ? catalogsAccessibleByInstructorId[instructorId]
            : []

        // todo(ashold12): the dart app renders all TA sections, however this is potentially incorrect as all instructors may not have
        // access to the current catalog. Here I am pruning sections of profs which do not have access to the current catalog.
        // however, if they have no catalogs I am assuming they are an admin and rendering anyways. This is not perfect
        // but I believe it's an improvement over the previous implementation as we can not interrogate the user's role
        return (
          instructorCatalogs.includes(catalogId) ||
          instructorCatalogs.length === 0
        )
      }
    )
    // fetch sections for the pruned instructors
    return await fetchSectionsForInstructors(this.repository, {
      instructorIds: instructorsWithCatalogAccess,
    })
  }

  async createSectionAssignment(params: {
    sectionId: string
    assignmentType: AssignmentType
    expiresAt: DateTime
    groupingType: AssignmentGroupingType
    slideDeckId: string
    catalogId?: string
    groupingSize?: number
  }) {
    return createSectionAssignment(this.repository, params)
  }
}
