import { computed, makeObservable, observable } from 'mobx'

import type { StaticModelCollection } from '../firestore-mobx/model'
import { getCatalogs, getMyCatalogs } from '../firestore/Catalog'
import { getSlideDecks, getSlideDecksForCatalog } from '../firestore/SlideDeck'
import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import { Cubit } from './core'

export class InstructorCatalogsCubit extends Cubit {
  repository: FirebaseRepository

  catalogs: StaticModelCollection<Catalog>

  private _adminSlideDecks: StaticModelCollection<SlideDeck>

  private _slideDecksByCatalogId = observable.map<
    string,
    StaticModelCollection<SlideDeck>
  >()

  private _isAdmin: boolean

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository
    this.catalogs = Catalog.emptyCollection(repository)
    this._adminSlideDecks = SlideDeck.emptyCollection(repository)

    this._isAdmin =
      repository.breakoutUser !== null && repository.breakoutUser.isAdmin
  }

  @computed
  get isLoading() {
    const isAdminLoading =
      this.hasStream('admin-slide-decks') && this._adminSlideDecks.isLoading
    return (
      this.catalogs.isLoading ||
      isAdminLoading ||
      Array.from(this._slideDecksByCatalogId.values()).some(
        (collection) => collection.isLoading
      )
    )
  }

  @computed
  get slideDecksByCatalogId() {
    if (!this._isAdmin) {
      // convert to a plain object
      return Object.fromEntries(
        Array.from(this._slideDecksByCatalogId.entries()).map(
          ([key, value]) => [key, value.models]
        )
      )
    }
    const slideDecksByCatalog: Record<string, SlideDeck[]> = {}
    this.catalogs.models.forEach((catalog) => {
      slideDecksByCatalog[catalog.id] = []
    })
    this._adminSlideDecks.models.forEach((slideDeck) => {
      if (slideDeck.slideDeckState !== SlideDeckState.published) return
      for (const catalogId of slideDeck.data.catalogIds) {
        if (!(catalogId in slideDecksByCatalog)) continue
        slideDecksByCatalog[catalogId].push(slideDeck)
      }
    })
    return slideDecksByCatalog
  }

  @computed
  get slideDecks() {
    if (!this._isAdmin) {
      const soFar = new Set()
      const slideDecks = Array.from(this._slideDecksByCatalogId.values())
        .map((collection) => collection.models)
        .flat()
      // don't allow duplicate slide decks
      return slideDecks.filter((slideDeck) => {
        if (soFar.has(slideDeck.id)) return false
        soFar.add(slideDeck.id)
        return true
      })
    }
    return this._adminSlideDecks.models
  }

  initialize(): void {
    // if user is an admin call getCatalogs
    // otherwise call getMyCatalogs
    if (this.repository.breakoutUser && this.repository.breakoutUser.isAdmin) {
      this.addStream(
        getCatalogs(this.repository),
        (catalogs) => {
          this.catalogs.replaceModels(catalogs)
        },
        {
          name: 'catalogs',
        }
      )
      this.addStream(
        getSlideDecks(this.repository),
        (slideDecks) => {
          this._adminSlideDecks.replaceModels(slideDecks)
        },
        {
          name: 'admin-slide-decks',
        }
      )
    } else {
      this.addStream(
        getMyCatalogs(this.repository),
        (catalogs) => {
          this.catalogs.replaceModels(catalogs)

          this.addSlideDeckStreams()
        },
        {
          name: 'catalogs',
        }
      )
    }
  }

  addSlideDeckStreams() {
    if (this.hasStream('admin-slide-decks')) return
    this.removeStreams('slide-decks')

    for (const catalog of this.catalogs.models) {
      this.addStream(
        getSlideDecksForCatalog(this.repository, {
          catalogId: catalog.id,
        }),
        (slideDecks) => {
          const found = this._slideDecksByCatalogId.get(catalog.id)

          if (found) {
            found.replaceModels(slideDecks)
          } else {
            const collection = SlideDeck.emptyCollection(this.repository)
            collection.replaceModels(slideDecks)
            this._slideDecksByCatalogId.set(catalog.id, collection)
          }
        },
        {
          namespace: 'slide-decks',
        }
      )
    }
  }
}
