import type { ObservableMap } from 'mobx'
import { observable } from 'mobx'
import type { TranscriptMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'

export class SlideTimerController {
  meeting: MeetingCubit
  _messages: ObservableMap<number, TranscriptMessage> = observable.map({})

  slideTimer: NodeJS.Timeout | null = null
  debugMode = false

  constructor(meeting: MeetingCubit, options: { debug?: boolean } = {}) {
    this.meeting = meeting
    this.debugMode = options.debug || false
  }

  cancelSlideTimer() {
    this.debugLog('cancelSlideTimer')
    if (this.slideTimer) {
      clearInterval(this.slideTimer)
    }
  }

  resetSlideTimer() {
    this.debugLog('resetSlideTimer')
    this.meeting.updateProgressBar(0)
    this.cancelSlideTimer()

    this.calculateSlideTimer()
    this.slideTimer = setInterval(() => {
      this.calculateSlideTimer()
    }, 1000)
  }

  calculateSlideTimer() {
    this.debugLog('calculateSlideTimer')
    const slideDuration = this.meeting.currentSlide?.duration
    const slideChangedAt = this.meeting.activeSlideChangedAt
    if (!slideChangedAt || !slideDuration) {
      this.debugLog('slideChangedAt or slideDuration is not set', {
        slideChangedAt,
        slideDuration,
      })
      return
    }

    const elapsedSeconds = (Date.now() - slideChangedAt.getTime()) / 1000

    const currentProgress = elapsedSeconds / slideDuration

    const timeDifference = elapsedSeconds % 5

    const durationPlusMargin = slideDuration + 60

    const maxDuration = slideDuration * 10

    /// If elapsed time is less than slide duration + 1 minute
    /// or there is an integer of 5 seconds since the last time
    /// we updated the progress bar
    if (elapsedSeconds < durationPlusMargin || timeDifference === 0) {
      this.meeting.updateProgressBar(
        currentProgress === 0 ? 0.0001 : currentProgress
      )
    } else {
      console.log('skipping progress update')
    }

    if (elapsedSeconds >= durationPlusMargin) {
      this.endTimer()
    }

    this.debugLog({
      slideDuration,
      elapsedSeconds,
      maxDuration,
      durationPlusMargin,
      timeDifference,
    })

    if (elapsedSeconds >= maxDuration) {
      this.endTimer()
    }
  }

  endTimer() {
    this.cancelSlideTimer()
    this.meeting.updateProgressBar(1)
  }

  debugLog(...args: unknown[]) {
    if (this.debugMode) {
      console.log(...args)
    }
  }
}
