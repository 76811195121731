import type {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
} from 'firebase/firestore'
import type { CollectionReference } from 'firebase/firestore'
import { onSnapshot } from 'firebase/firestore'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { MobxDocument } from '../types'
import { StreamController } from 'tricklejs'
import type { StreamInterface } from 'tricklejs/dist/types'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import type { ObservableModel, ObservableModelClass } from './model'

function printError(e: Error) {
  let shouldPrint = true
  // A lot of errors are expected to appear in tests and make the output very noisy
  // should you need to inspect errors, you can pass PRINT_FIRESTORE
  if (process.env.NODE_ENV === 'test') shouldPrint = false
  if (process.env.PRINT_FIRESTORE === 'test') shouldPrint = true

  if (shouldPrint) {
    console.log('Firestore error', e)
  }
}

export class WrappedFirestoreError extends Error {}

/**
 * Replacement for .snapshots() in Dart
 */
export function documentSnapshots<T>(ref: DocumentReference<T, DocumentData>) {
  const controller = new StreamController<DocumentSnapshot<T, DocumentData>>()

  const stack = new Error().stack
  let disposer: () => void = () => {}

  controller.onListen = () => {
    disposer = onSnapshot(
      ref,
      (snapshot) => {
        controller.add(snapshot)
      },
      (error) => {
        const err = new WrappedFirestoreError('Firestore error')
        err.name = 'WrappedFirestoreError'
        err.message = error.message
        err.stack = stack
        printError(err)
        controller.addError(error)
      }
    )
  }

  controller.onCancel = () => {
    disposer()
  }

  return controller.stream
}

/**
 * Replacement for .snapshots() in Dart
 */
export function collectionSnapshots<T>(
  ref: CollectionReference<T, DocumentData> | Query<T>
) {
  const controller = new StreamController<QuerySnapshot<T, DocumentData>>()

  let disposer: () => void = () => {}
  const stack = new Error().stack

  controller.onListen = () => {
    disposer = onSnapshot(
      ref,
      (snapshot) => {
        controller.add(snapshot)
      },
      (error) => {
        const err = new WrappedFirestoreError('Firestore error')
        err.name = 'WrappedFirestoreError'
        err.message = error.message
        err.stack = stack
        printError(err)
        controller.addError(error)
      }
    )
  }

  controller.onCancel = () => {
    // console.log('disposing', debugRef(ref))
    disposer()
  }

  return controller.stream
}

export function convertDocumentSnapshotToModel<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  doc: DocumentSnapshot<T>,
  modelClass: ObservableModelClass<T, M>
) {
  const mobxDoc = {
    id: doc.id,
    ref: doc.ref,
    fromCache: doc.metadata.fromCache,
    hasPendingWrites: doc.metadata.hasPendingWrites,
    data: doc.data({
      serverTimestamps: 'estimate',
    }),
  } as MobxDocument<T>
  return new modelClass(repository, mobxDoc)
}

/**
 * Convenience function to turn a Firestore doc into a model
 */
export function modelItemStream<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  ref: DocumentReference<T>,
  modelClass: ObservableModelClass<T, M>
): StreamInterface<M> {
  const result = documentSnapshots(ref).map((snapshot) => {
    return convertDocumentSnapshotToModel(repository, snapshot, modelClass)
  })
  return result
}

/**
 * Convenience method to turn a list of Firestore docs into a list of models
 */
export function modelListStream<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  ref: CollectionReference<T> | Query<T>,
  modelClass: ObservableModelClass<T, M>
): StreamInterface<M[]> {
  const result = collectionSnapshots(ref).map((snapshot) => {
    const models = snapshot.docs.map((doc) => {
      return convertDocumentSnapshotToModel(repository, doc, modelClass)
    })
    return models
  })
  return result
}

export class CollectionSnapshotStreamCollector<T> {
  controller: StreamController<T[]>
  subscriptions: StreamSubscriptionActions[] = []
  streams: StreamInterface<T[]>[] = []
  streamResults = new Map<StreamInterface<T[]>, T[]>()

  constructor() {
    this.controller = new StreamController<T[]>()

    this.controller.onCancel = () => this.close()
  }

  get stream(): StreamInterface<T[]> {
    return this.controller.stream
  }

  close() {
    this.subscriptions.forEach((s) => s.cancel())
    this.controller.close()
  }

  attachStream(stream: StreamInterface<T[]>) {
    this.streams.push(stream)
    const subscription = stream.listen((values) => {
      this.streamResults.set(stream, values)
      this.flush()
    })
    this.subscriptions.push(subscription)
  }

  flush() {
    const values = this.streams
      .map((s) => this.streamResults.get(s) || [])
      .flat()
    this.controller.add(values)
  }
}
