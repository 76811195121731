import { makeObservable } from 'mobx'
import { PublicUser } from '../models/PublicUser'

import type { StaticModelCollection } from '../firestore-mobx/model'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import {
  deleteTARecordFromAppUser,
  getMyAssistants,
} from '../firestore/PublicUser'
import { createTaInvitation } from '../firestore/Invitation'

export class InstructorAssistantsCubit extends Cubit {
  repository: FirebaseRepository

  public assistants: StaticModelCollection<PublicUser>

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository
    this.assistants = PublicUser.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(getMyAssistants(this.repository), (assistants) => {
      this.assistants.replaceModels(assistants)
    })
  }

  async createInvitation() {
    const doc = await createTaInvitation(this.repository)

    return doc.id
  }

  async removeAssistant(userId: string) {
    return deleteTARecordFromAppUser(this.repository.firestore, {
      instructorUserId: this.repository.uid,
      userId: userId,
    })
  }
}
