import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required List<String> groupLeaderUserIds,
// required List<String> hiddenUserIds,
// required String slideDeckId,
// required List<String> userIds,
// String? activeExhibitId,
// int? activeSlide,
// int? activeSlideChangedAt,
// String? assignmentId,
// String? roomStateName,
// int? scheduledAt,
// String? sectionId,
// int? updatedAt,
// String? videoMethod,
export const schema = z.object({
  groupLeaderUserIds: z
    .array(z.string())
    .nullable()
    .transform((val) => val ?? []),
  hiddenUserIds: z.array(z.string()).nullish().optional(),
  slideDeckId: z.string(),
  userIds: z.array(z.string()),
  activeExhibitId: z.string().optional().nullable(),
  activeSlide: z.number().optional().nullable(),
  activeSlideChangedAt: firestoreTimestamp.nullish().optional(),
  assignmentId: z.string().optional(),
  roomStateName: z.string().nullish().optional().default(''),
  scheduledAt: firestoreTimestamp.nullish(),
  sectionId: z.string().optional(),
  videoMethod: z.string().nullish().optional().default('broadcast'),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    groupLeaderUserIds: [],
    hiddenUserIds: [],
    slideDeckId: '',
    userIds: [],
    activeExhibitId: '',
    activeSlide: 0,
    activeSlideChangedAt: { seconds: 0, nanoseconds: 0 },
    assignmentId: '',
    roomStateName: '',
    scheduledAt: null,
    sectionId: '',
    videoMethod: 'broadcast',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  scheduledAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateInferred = z.infer<typeof schema>
export type FirestoreRoomState = FirestoreRoomStateInferred
export type FirestoreRoomStateWrite = z.infer<typeof schema>
