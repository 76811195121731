import type { CollectionReference, DocumentData } from 'firebase/firestore'
import {
  collection,
  getDocs,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreSlideCaption } from './schema'
import { schema } from './schema'
import { convertDocumentSnapshotToModel } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideCaption } from '../../models/SlideCaption'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideCaption> = {
  toFirestore: () => {
    throw new Error('Client cannot write FirestoreRoomStateSummary')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getCollectionRef = (
  firestore: Firestore,
  params: {
    slideDeckId: string
    slideId: string
  }
): CollectionReference<FirestoreSlideCaption, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'slide',
    params.slideId,
    'slide_caption'
  ).withConverter(converter)
}

export const fetchSlideCaptions = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
    slideId: string
  }
) => {
  const ref = getCollectionRef(repository.firestore, params)

  const snapshot = await getDocs(ref)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideCaption)
  })
}
