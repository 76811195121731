import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

enum BloomScore {
  none = 0,
  remember = 1,
  understand = 2,
  apply = 3,
  analyze = 4,
  evaluate = 5,
  create = 6,
}

const score = z.nativeEnum(BloomScore)

export const schema = z.object({
  arguments: z.array(z.string()),
  justification: z.string(),
  roomId: z.string(),
  rubricId: z.string(),
  score: score,
  userId: z.string(),
  assignmentId: z.string().optional(),
  sectionId: z.string().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    arguments: [],
    justification: '',
    roomId: '',
    score: 0,
    rubricId: '',
    userId: '',
    assignmentId: '',
    sectionId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateRubricResultInferred = z.infer<typeof schema>
export interface FirestoreRoomStateRubricResult
  extends FirestoreRoomStateRubricResultInferred {}
export type FirestoreRoomStateRubricResultWrite = z.infer<typeof schema>
