import type {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { computed, makeObservable, action } from 'mobx'
import { InvitationConsumeResult, type FirestoreInvitation } from '../types'
import type { FirebaseRepository } from './FirebaseRepository.js'
import { UserProfileTokens } from '../stores/UserProfileTokens.js'
import { getPublicUser } from '../firestore/PublicUser'
import { PublicUser } from './PublicUser'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import { getUserProfile } from '../firestore/UserProfile'
import { UserProfile } from './UserProfile'

type CreateInvitationResult =
  | {
      result: InvitationConsumeResult.success
      invitation?: FirestoreInvitation
    }
  | {
      result:
        | InvitationConsumeResult.errorAlreadyConsumed
        | InvitationConsumeResult.errorCreatedByYou
        | InvitationConsumeResult.errorInvitationExpired
        | InvitationConsumeResult.errorInvitationNotFound
    }

// TODO: move to stores
/**
 * A BreakoutUser is a user of the Breakout platform, it is composed of a user profile and a public user.
 **/
export class BreakoutUser {
  uid: string
  firestore: Firestore
  repository: FirebaseRepository

  private userProfileTokens: UserProfileTokens
  private _onboardCompleteLocal = false
  private userStreamSubscription: StreamSubscriptionActions | undefined
  private profileStreamSubscription: StreamSubscriptionActions | undefined

  publicUser: PublicUser
  userProfile: UserProfile

  constructor(repository: FirebaseRepository, uid: string) {
    this.uid = uid
    this.repository = repository
    const firestore = repository.firestore
    this.firestore = firestore
    this.userProfileTokens = new UserProfileTokens(repository, this)
    this.publicUser = PublicUser.empty(repository)
    this.userProfile = UserProfile.empty(repository)

    makeObservable(this, {
      firstName: computed,
      lastName: computed,
      emailAddress: computed,
      role: computed,
      isLoading: computed,
      hasData: computed,
      user: computed,
      isAdmin: computed,
      isStudent: computed,
      isFaculty: computed,
      shouldShowOnboarding: computed,
      setOnboardingComplete: action,
    })
  }

  initialize() {
    this.userProfileTokens.initialize()
  }

  startUserStreamIfNotRunning() {
    if (!this.uid) return
    if (this.userStreamSubscription) return

    const userStream = getPublicUser(this.repository, { userId: this.uid })
    this.userStreamSubscription = userStream.listen((user) => {
      this.publicUser.replaceModel(user)
    })
  }

  startProfileIfNotRunning() {
    if (!this.uid) return
    if (this.profileStreamSubscription) return

    const profileStream = getUserProfile(this.repository, { userId: this.uid })
    this.profileStreamSubscription = profileStream.listen((profile) => {
      this.userProfile.replaceModel(profile)
    })
  }

  dispose() {
    this.userProfileTokens.dispose()
    this.userStreamSubscription?.cancel()
    this.profileStreamSubscription?.cancel()
  }

  get user() {
    this.startUserStreamIfNotRunning()
    return this.publicUser
  }

  get profile() {
    this.startProfileIfNotRunning()
    return this.userProfile
  }

  get photoURL() {
    return this.user.data.imageUrl
  }

  async consumeInvitation(
    invitationId: string
  ): Promise<CreateInvitationResult> {
    if (!this.uid) {
      throw new Error('User must be logged in to consume invitation')
    }

    const collectionRef = collection(
      this.firestore,
      'invitation'
    ).withConverter({
      toFirestore: (data) => data,
      fromFirestore: (
        snap: QueryDocumentSnapshot<DocumentData, DocumentData>
      ) => snap.data() as FirestoreInvitation,
    })
    try {
      const invitationRef = doc(collectionRef, invitationId)

      const userInvitationRef = doc(
        collection(invitationRef, 'users'),
        this.uid
      )

      const alreadyConsumed = (await getDoc(userInvitationRef)).exists()

      if (alreadyConsumed)
        return { result: InvitationConsumeResult.errorAlreadyConsumed }

      // most of the time this will not work, we can only fetch if admin or we created in the invite
      const invitationDocInitial = await getDoc(invitationRef).catch(
        () => undefined
      )

      if (invitationDocInitial) {
        if (invitationDocInitial.exists()) {
          const invitation = invitationDocInitial.data()

          if (invitation.userId === this.uid)
            return { result: InvitationConsumeResult.errorCreatedByYou }
        } else {
          return { result: InvitationConsumeResult.errorInvitationNotFound }
        }
      }

      await setDoc(userInvitationRef, {
        updatedAt: serverTimestamp(),
      })

      // still may not have sufficient permissions to fetch
      // as long as update was successful, we can return success
      const invitationDoc = await getDoc(invitationRef).catch(() => undefined)
      const invitation = invitationDoc?.data()

      this.repository.logEvent('invitation_accepted', {
        invitation_id: invitationId,
      })

      return { result: InvitationConsumeResult.success, invitation }
    } catch (e) {
      return { result: InvitationConsumeResult.errorInvitationExpired }
    }
  }

  get hasData() {
    return this.profile.hasData && this.user.hasData
  }

  get isLoading() {
    return this.profile.isLoading || this.user.isLoading
  }

  get isLoaded() {
    return this.profile.isLoaded && this.user.isLoaded
  }

  get firstName() {
    return this.user.data.firstName
  }

  get lastName() {
    return this.user.data.lastName
  }

  get emailAddress() {
    return this.profile.data.emailAddress
  }

  get role() {
    return this.profile.hasData && this.profile.data.role
  }

  get isAdmin() {
    return this.role === 'admin'
  }

  get isStudent() {
    return this.role === 'student'
  }

  get isInstructor() {
    return this.role === 'instructor'
  }

  get isTA() {
    return this.role === 'ta'
  }

  get isFaculty() {
    return this.role === 'instructor' || this.role === 'ta'
  }

  get shouldShowOnboarding() {
    if (!this.profile.hasData) return false
    return !this.profile.data.onboardComplete && !this._onboardCompleteLocal
  }

  get tokensLoading() {
    if (!this.uid) return false

    return this.userProfileTokens.isLoading
  }

  get tokensLoaded() {
    return !this.tokensLoading
  }

  get purchasesLoading() {
    if (!this.uid) return false

    return this.userProfileTokens.isLoading
  }

  get purchasesLoaded() {
    return !this.purchasesLoading
  }

  get tokens() {
    if (!this.uid) return []

    return this.userProfileTokens.tokens
  }

  get purchases() {
    if (!this.uid) return []

    return this.userProfileTokens.purchases
  }

  get availableTokens() {
    return this.userProfileTokens.tokensAvailable
  }

  get consumedTokens() {
    return this.userProfileTokens.tokensConsumed
  }

  updateName = async (firstName: string, lastName: string) => {
    if (!this.uid) return

    const payload: { [key: string]: string } = {}

    if (firstName) payload['firstName'] = firstName
    if (lastName) payload['lastName'] = lastName

    if (Object.keys(payload).length === 0) return

    const ref = doc(this.firestore, 'users', this.uid)

    await updateDoc(ref, payload)
  }

  setOnboardingComplete = () => {
    if (!this.uid) {
      throw new Error('User must be logged in to set onboarding complete')
    }

    this._onboardCompleteLocal = true

    if (this.profile.data.onboardComplete) return

    const ref = doc(this.firestore, 'user_profile', this.uid)

    // we don't need to wait for this to complete
    updateDoc(ref, {
      onboardComplete: true,
    })
  }
}
