import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required String id,
// required String exhibitDescription,
// required bool exhibitHasThumbnail,
// required String exhibitName,
// String? slideId,
// String? exhibitURL,
// int? updatedAt,
export const schema = z.object({
  exhibitDescription: z.string(),
  exhibitHasThumbnail: z.boolean().optional().default(false),
  exhibitName: z.string().default(''),
  slideId: z.string().optional().default(''),
  exhibitURL: z.string().optional().default(''),
  updatedAt: firestoreTimestamp,
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
  exhibitURL: z.string().optional(),
  exhibitHasThumbnail: z.boolean().optional(),
})

export const empty = Object.freeze(
  schema.parse({
    exhibitDescription: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSlideDeckExhibit = z.infer<typeof schema>
export type FirestoreSlideDeckExhibitWrite = z.infer<typeof writeSchema>
