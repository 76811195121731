import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
// required String firstName,
// required String lastName,
// String imageUrl,
// int? updatedAt,
export const schema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  imageUrl: z
    .string()
    .nullish()
    .optional()
    .transform((val) => (val ? val : '')),
  updatedAt: firestoreTimestamp
    .optional()
    .transform((val) => (val ? val : new Date())),
})

export const empty = Object.freeze(
  schema.parse({
    firstName: '',
    lastName: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

type FirestorePublicUserType = z.infer<typeof schema>
export interface FirestorePublicUser extends FirestorePublicUserType {}
