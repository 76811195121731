import {
  collection,
  doc,
  getDoc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreSettingsCatalogs } from './schema'
import { schema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSettingsCatalogs> = {
  toFirestore: (user: FirestoreSettingsCatalogs) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

export const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'catalogs'
  )
}

export const fetchSettingsProtectedCatalogIds = async (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDoc(docRef)

  if (doc.exists()) {
    const data = doc.data()
    return data.protected
  } else {
    return []
  }
}
