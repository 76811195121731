import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required this.consumed,
// required this.tokenQuantity,
// this.assignmentId,
// this.consumedCount,
// this.paymentId,
// this.price,
// this.roomId,
// this.sectionId,
export const baseSchema = z.object({
  consumed: z.boolean(),
  tokenQuantity: z.number(),
  assignmentId: z.string().nullish().optional(),
  consumedCount: z.number().nullish().optional(),
  paymentId: z.string().nullish().optional(),
  roomId: z.string().nullish().optional(),
  sectionId: z.string().nullish().optional(),
  price: z.number().nullish().optional(),
  creatorUserId: z.string().nullish().optional(),
  purchaseType: z.string().nullish().optional(),
  userId: z.string().optional(),
  createdAt: firestoreTimestamp.nullish().optional(),
  updatedAt: firestoreTimestamp,
})

export const schema = baseSchema.superRefine((data) => {
  // If createdAt is not provided, default it to updatedAt
  if (!data.createdAt) {
    data.createdAt = data.updatedAt
  }
})

export const writeSchema = baseSchema.extend({
  creatorUserId: z.string(),
  createdAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    consumed: false,
    tokenQuantity: 0,
    assignmentId: '',
    consumedCount: 0,
    paymentId: '',
    price: 0,
    roomId: '',
    sectionId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
    createdAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreUserProfileToken = z.infer<typeof schema>
