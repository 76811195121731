import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
// required String slideDeckId,
// required String userId,
// int? updatedAt,

// this is the dart definition
// required String firstName,
// required String lastName,
// String imageUrl,
// int? updatedAt,
export const schema = z.object({
  firstName: z.string().optional().default(''),
  lastName: z.string().optional().default(''),
  imageUrl: z
    .string()
    .nullish()
    .optional()
    .transform((val) => (val ? val : '')),
  emailAddress: z
    .string()
    .optional()
    .default('')
    .transform((val) => (val ? val : '')),
  role: z.string(), // should be an enum
  onboardComplete: z.boolean().default(false),
  updatedAt: firestoreTimestamp
    .optional()
    .transform((val) => (val ? val : new Date())),
})

export const empty = Object.freeze(
  schema.parse({
    firstName: '',
    lastName: '',
    imageUrl: '',
    emailAddress: '',
    role: 'student',
    onboardComplete: false,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreUserProfile extends z.infer<typeof schema> {}
