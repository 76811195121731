import { computed } from 'mobx'
import type { MobxDocument } from '../firestore-mobx/document'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  SlideDeckState,
  empty,
  type FirestoreSlideDeck,
} from '../firestore/SlideDeck/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export { SlideDeckState }

export class SlideDeck extends ObservableModelWithDecorators<FirestoreSlideDeck> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideDeck>
  ) {
    super(repository, doc)
  }

  @computed
  get isArchived() {
    return this.isPublished && this.data.catalogIds.length === 0
  }

  @computed
  get isPublished() {
    return this.data.slideDeckState === SlideDeckState.published
  }

  @computed
  get readOnly() {
    return this.data.slideDeckState >= SlideDeckState.published
  }

  @computed
  get slideDeckState() {
    if (this.isArchived) {
      return SlideDeckState.hidden
    }
    return this.data.slideDeckState
  }
}
