import type { PreviewMeetingCubit } from '../../PreviewMeetingCubit'
import { MeetingDataFetcher } from '../MeetingDataFetcher'

export class PreviewDataFetcher extends MeetingDataFetcher {
  previewMeeting: PreviewMeetingCubit
  constructor(meeting: PreviewMeetingCubit) {
    super(meeting)
    this.previewMeeting = meeting
  }

  async start() {
    const slideDeckId = this.previewMeeting._slideDeckId
    this.fetchSlideDeck(slideDeckId)

    this.fetchExhibits(slideDeckId)
    this.fetchSlides(slideDeckId)
    this.fetchQuestions(slideDeckId)
    this.fetchSettingsProcessingVideo()
  }

  fetchUsers(/*userIds: string[]*/): Promise<void> {
    return Promise.resolve()
  }

  listenToQuestionAnswers(/*questionId: string*/) {
    return Promise.resolve()
  }

  getUserAnswers(): Promise<void> {
    return Promise.resolve()
  }

  getGroupAnswers(): Promise<void> {
    return Promise.resolve()
  }
}
