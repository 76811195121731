import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'
import { SlideType } from '../../types'

const slideTypeSchema = z.nativeEnum(SlideType)

export const schema = z.object({
  slideName: z.string(),
  slideQuestions: z.array(z.string()).optional().default([]),
  slideType: slideTypeSchema.default(0),
  slideDescription: z.string(),
  slideDuration: z.number(),
  slideImageURL: z.string().optional().default(''),
  slideOrder: z.number().optional().default(0),
  slideStorageURL: z.string().optional().default(''),
  slideVideoDuration: z.number().optional().default(0),
  slideVideoError: z.string().optional().default(''),
  slideVideoURL: z.string().optional().default(''),
  updatedAt: firestoreTimestamp,
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    slideName: '',
    slideQuestions: [],
    slideType: 0,
    slideDescription: '',
    slideDuration: 0,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSlide = z.infer<typeof schema>
