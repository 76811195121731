import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { BreakoutUser } from '../models/BreakoutUser'
import type { SectionAssignment } from '../models/SectionAssignment'
import type { Section } from '../models/Section'
import type { RoomState } from '../models/RoomState'
import type { SlideDeck } from '../models/SlideDeck'
import { LibraryObjectState } from '../types'
import { computed } from 'mobx'
import { DateTime } from 'luxon'
import { assert } from '../firestore-mobx/utils'
import { SectionState } from '../firestore/Section'

export class ValidLibraryObject {
  repository: FirebaseRepository
  user: BreakoutUser
  section: Section
  assignment: SectionAssignment
  roomState: RoomState
  slideDeck: SlideDeck

  constructor(params: {
    repository: FirebaseRepository
    section: Section
    assignment: SectionAssignment
    roomState: RoomState
    slideDeck: SlideDeck
  }) {
    const user = params.repository.breakoutUser
    assert(user, 'User must be logged in')
    this.user = user

    this.repository = params.repository
    this.section = params.section
    this.assignment = params.assignment
    this.roomState = params.roomState
    this.slideDeck = params.slideDeck
  }

  @computed
  get libraryObjectId() {
    // if there is a roomState, use the roomState id
    if (this.roomState.id) return this.assignment.id + '-' + this.roomState.id
    return this.assignment.id
  }

  @computed
  get slideDeckId() {
    return this.slideDeck.id
  }

  @computed
  get slideDeckName() {
    return this.slideDeck.data.slideDeckName
  }

  @computed
  get slideDeckPrice() {
    return this.slideDeck.data.slideDeckPrice
  }

  @computed
  get slideDeckImageURL() {
    return this.slideDeck.data.slideDeckImageURL
  }

  @computed
  get slideDeckTeaser() {
    return this.slideDeck.data.slideDeckTeaser
  }

  @computed
  get requiresPayment() {
    if (
      this.assignment.isExpired ||
      this.section.data.sectionState === SectionState.completed
    )
      return false
    const matchingTokensCount = this.user.tokens
      .filter((tokenModel) => {
        const token = tokenModel.data
        return (
          (token.consumed === true &&
            token.sectionId !== null &&
            token.assignmentId !== null &&
            token.sectionId === this.section.id &&
            token.assignmentId === this.assignment.id) ||
          (this.roomState.isNotEmpty &&
            token.roomId !== null &&
            token.roomId === this.roomState.id)
        )
      })
      .reduce((previousValue, element) => {
        return previousValue + element.data.tokenQuantity
      }, 0)

    const havePurchaseRecord = this.user.purchases.some(
      (purchase) => purchase.id === this.slideDeck.id
    )

    const result =
      (matchingTokensCount < this.slideDeck.data.slideDeckPrice ||
        !havePurchaseRecord) &&
      this.user.role === 'student' &&
      !this.slideDeck.data.slideDeckFree

    return result
  }

  @computed
  get beforeSession() {
    return (
      this.libraryObjectState === LibraryObjectState.enrolled ||
      this.libraryObjectState === LibraryObjectState.invited ||
      this.libraryObjectState === LibraryObjectState.mustSchedule ||
      this.libraryObjectState === LibraryObjectState.pending ||
      this.libraryObjectState === LibraryObjectState.scheduled
    )
  }

  @computed
  get afterSession() {
    return (
      this.libraryObjectState === LibraryObjectState.completed ||
      this.libraryObjectState === LibraryObjectState.expired
    )
  }

  @computed
  get groupLeader() {
    return this.roomState.groupLeaderUserIds.includes(this.user.uid)
  }

  @computed
  get libraryObjectState(): LibraryObjectState {
    if (!this.roomState.id) {
      if (
        this.assignment.isExpired ||
        this.section.data.sectionState === SectionState.completed
      )
        return LibraryObjectState.expired
      if (this.requiresPayment) return LibraryObjectState.invited
      return LibraryObjectState.enrolled
    }

    if (!this.roomState.isActive) {
      if (
        this.assignment.isExpired ||
        this.section.data.sectionState === SectionState.completed
      )
        return LibraryObjectState.expired
      if (this.requiresPayment) return LibraryObjectState.invited
      if (this.roomState.isScheduled) {
        if (this.canStart) return LibraryObjectState.readyToStart
        return LibraryObjectState.scheduled
      }
      if (this.groupLeader) return LibraryObjectState.mustSchedule
      return LibraryObjectState.pending
    }

    if (this.roomState.isCompleted) return LibraryObjectState.completed
    if (this.requiresPayment) return LibraryObjectState.invited
    return LibraryObjectState.live
  }

  @computed
  get canStart() {
    const scheduledAt = this.roomState.data.scheduledAt
    if (!scheduledAt) return false
    return (
      this.repository.currentMinute >
      DateTime.fromJSDate(scheduledAt).minus({ minutes: 10 })
    )
  }
}
