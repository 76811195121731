import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

export const schema = z.object({
  url: z.string().optional(),
  updatedAt: firestoreTimestamp.optional(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(schema.parse({}))

export interface FirestoreSectionAssignmentSlides
  extends z.infer<typeof schema> {}
