import { action, computed, makeObservable, observable } from 'mobx'

import { getCatalogs } from '../firestore/Catalog'
import {
  getSettingsProcessingVideo,
  uploadSettingsProcessingVideo,
} from '../firestore/SettingsProcessingVideo'
import { createSlideDeck, getSlideDecks } from '../firestore/SlideDeck'
import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SettingsProcessingVideo } from '../models/SettingsProcessingVideo'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import { SlideDeckGroup } from '../stores/SlideDeckGroup'
import type { StaticModelCollection } from '../types'
import { Cubit } from './core'

export class AdminLibraryCubit extends Cubit {
  repository: FirebaseRepository

  slideDecks: StaticModelCollection<SlideDeck>
  catalogs: StaticModelCollection<Catalog>
  settingsProcessingVideo: SettingsProcessingVideo

  @observable showHidden = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository
    this.slideDecks = SlideDeck.emptyCollection(repository)
    this.catalogs = Catalog.emptyCollection(repository)
    this.settingsProcessingVideo = SettingsProcessingVideo.empty(repository)
  }

  initialize(): void {
    this.addStream(getSlideDecks(this.repository), (rooms) => {
      this.slideDecks.replaceModels(rooms)
    })
    this.addStream(getCatalogs(this.repository), (catalogs) => {
      this.catalogs.replaceModels(catalogs)
    })
    this.addStream(
      getSettingsProcessingVideo(this.repository),
      (settingsProcessingVideo) => {
        this.settingsProcessingVideo.replaceModel(settingsProcessingVideo)
      }
    )
  }

  @computed
  get slideDecksGroups() {
    const grouped = new Map<string, SlideDeckGroup>()

    const filtered = this.showHidden
      ? this.slideDecks.models
      : this.slideDecks.models.filter((slideDeck) => {
          return slideDeck.data.slideDeckState !== SlideDeckState.hidden
        })

    filtered.forEach((slideDeck) => {
      const parentId = slideDeck.data.slideDeckTypeId || slideDeck.id
      let group = grouped.get(parentId)

      if (!group) {
        group = new SlideDeckGroup(parentId)
        grouped.set(parentId, group)
      }

      group.addSlideDeck(slideDeck)
    })

    return Array.from(grouped.values()).sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime()
    })
  }

  /**
   * Add a new slide deck to the library
   *
   * @returns string - The ID of the newly created slide deck
   */
  addSlideDeck(): Promise<string> {
    return createSlideDeck(this.repository)
  }

  @action
  toggleShowHidden = () => {
    this.showHidden = !this.showHidden
  }

  uploadSettingsProcessingVideo = async (file: File) => {
    return uploadSettingsProcessingVideo(this.repository, file)
  }
}
