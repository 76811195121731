import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  QueryCompositeFilterConstraint,
  QueryFieldFilterConstraint,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  serverTimestamp,
  updateDoc,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { Section } from '../../models/Section'
import { SectionState } from '../../types'
import type { FirestoreSection } from './schema'
import { schema, writeSchema } from './schema'
import { StreamController } from 'tricklejs'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSection> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSection>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSection> => {
  return collection(firestore, 'section').withConverter(converter)
}

// @ts-expect-error - just a matter of time until this is used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  { sectionId }: { sectionId: string }
): DocumentReference<FirestoreSection, DocumentData> => {
  return doc(getColRef(firestore), sectionId)
}

export const getSections = (repository: FirebaseRepository) => {
  const q = query(
    getColRef(repository.firestore),
    or(
      where('userIds', 'array-contains', repository.uid),
      where('instructorUserId', '==', repository.uid)
    )
  )
  return modelListStream(repository, q, Section)
}

export const getSectionsStreamForInstructor = (
  repository: FirebaseRepository,
  {
    instructorUserId,
  }: {
    instructorUserId?: string
  } = {}
) => {
  const ref = getColRef(repository.firestore)
  const userId = instructorUserId || repository.uid

  const predicate: QueryFieldFilterConstraint | QueryCompositeFilterConstraint =
    where('instructorUserId', '==', userId)

  const q = query(ref, predicate as QueryFieldFilterConstraint)

  return modelListStream(repository, q, Section)
}

export const getSectionsStreamForInstructors = (
  repository: FirebaseRepository,
  instructorIds: string[]
) => {
  // return empty stream if no instructorIds
  if (!instructorIds.length) {
    const controller = new StreamController<Section[]>()
    controller.add([])
    controller.close()
    return controller.stream
  }
  const catalogRef = getColRef(repository.firestore)
  const catalogQuery = query(
    catalogRef,
    where('instructorUserId', 'in', instructorIds)
  )
  return modelListStream(repository, catalogQuery, Section)
}

export const fetchSectionsForInstructors = async (
  repository: FirebaseRepository,
  { instructorIds }: { instructorIds: string[] }
) => {
  if (!instructorIds.length) return []
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('instructorUserId', 'in', instructorIds))

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, Section)
  })
}

export const fetchInstructorSections = async (
  repository: FirebaseRepository,
  {
    instructorUserId,
  }: {
    instructorUserId?: string
  }
) => {
  const q = query(
    getColRef(repository.firestore),
    or(where('instructorUserId', '==', instructorUserId || repository.uid))
  )

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, Section)
  })
}

export const getSection = (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, sectionId)

  return modelItemStream(repository, docRef, Section)
}

export const fetchSection = async (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore)

  const docRef = doc(ref, sectionId)

  const snapshot = await getDoc(docRef)

  return convertDocumentSnapshotToModel(repository, snapshot, Section)
}

export const createSection = (
  repository: FirebaseRepository,
  {
    className,
    sectionName,
    userId,
  }: {
    userId: string
    className: string
    sectionName: string
  }
) => {
  const payload = {
    className,
    sectionName,
    instructorUserId: userId,
    userIds: [],
    sectionState: SectionState.notStarted,
    updatedAt: serverTimestamp(),
  }
  return addDoc(getColRef(repository.firestore), payload)
}

export async function setSectionState(
  repository: FirebaseRepository,
  params: {
    sectionId: string
    sectionState: SectionState
  }
) {
  const colRef = getColRef(repository.firestore)

  const docRef = doc(colRef, params.sectionId)

  return updateDoc(docRef, {
    sectionState: params.sectionState,
    updatedAt: serverTimestamp(),
  })
}

export async function setSectionInvoiced(
  repository: FirebaseRepository,
  params: {
    sectionId: string
  }
) {
  const colRef = getColRef(repository.firestore)

  const docRef = doc(colRef, params.sectionId)

  return updateDoc(docRef, {
    invoiced: true,
    updatedAt: serverTimestamp(),
  })
}
