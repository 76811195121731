import { z } from 'zod'
import {
  firestoreTimestamp,
  firestoreWriteTimestamp,
} from '../../firestore/shared/schema'
import { FieldValue } from 'firebase/firestore'

export enum SlideDeckState {
  deleted = -2,
  uninitialized = -1,
  draft = 0,
  published = 1,
  hidden = 2,
}

const slideDeckStateSchema = z.nativeEnum(SlideDeckState)

export const schema = z.object({
  catalogIds: z.array(z.string()).default([]),
  slideDeckFree: z.boolean().default(false),
  slideDeckName: z.string().default(''),
  slideDeckPrice: z.number().default(0),
  slideDeckState: slideDeckStateSchema.default(SlideDeckState.draft),
  slideDeckTypeId: z.string().default(''),
  slideDeckVersion: z.string().default('unknown'),
  slideDeckDescription: z.string().optional(),
  slideDeckDisciplines: z.array(z.string()).default([]),
  slideDeckFeatured: z.boolean().default(false),
  slideDeckGoogleTemplateURL: z.string().optional(),
  slideDeckImageURL: z.string().optional(),
  slideDeckIndustries: z.array(z.string()).default([]),
  slideDeckLearningObjectives: z.array(z.string()).default([]),
  slideDeckParentId: z.string().optional(),
  slideDeckTeaser: z.string().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    catalogIds: [],
    slideDeckFree: false,
    slideDeckName: '',
    slideDeckPrice: 0,
    slideDeckState: 0,
    slideDeckTypeId: '',
    slideDeckVersion: '',
    slideDeckLearningObjectives: [],
    slideDeckDisciplines: [],
    slideDeckIndustries: [],
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
  // allow sentinel values for catalogIds
  catalogIds: z
    .array(z.string())
    .default([])
    .or(
      z.custom((val) => val instanceof FieldValue, { message: 'FieldValue' })
    ),
})

export interface FirestoreSlideDeck extends z.infer<typeof schema> {}
