import { CollectionReference, addDoc, deleteField } from 'firebase/firestore'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { SlideDeckMaterialType, empty, schema } from './schema'
import type { FirestoreSlideDeckMaterial } from './schema'
import { FetchedCollection } from '../../firestore-mobx'
import { type FirebaseRepository } from '../../models/FirebaseRepository'
import {
  StaticModelCollection,
  StaticModelDocument,
} from '../../firestore-mobx/model'
import {
  SlideDeckMaterial,
  slideDeckMaterialUploadFieldsWithExtensionsFromMaterialType as materialUploadFieldsFromType,
} from '../../models/SlideDeckMaterial'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { type FieldValue } from 'firebase/firestore'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'

const converter: FirestoreDataConverter<FirestoreSlideDeckMaterial> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}
const getColRef = (
  firestore: Firestore,
  params: {
    slideDeckId: string
  }
): CollectionReference<FirestoreSlideDeckMaterial> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'material'
  ).withConverter(converter)
}

export const getFeaturedImageMaterial = (
  firestore: Firestore,
  slideDeckId: string
) => {
  const collectionRef = collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'material'
  ).withConverter(converter)
  const typeFilter = where(
    'materialType',
    '==',
    SlideDeckMaterialType.featuredLarge
  )
  const limitFilter = limit(1)
  return new FetchedCollection<FirestoreSlideDeckMaterial>(
    collectionRef,
    (col) => {
      return query(col, typeFilter, limitFilter)
    }
  )
}

export const fetchFeaturedSlideDeckMaterials = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, params)
  const limitFilter = limit(1)
  const typeFilter = where(
    'materialType',
    '==',
    SlideDeckMaterialType.featuredLarge
  )
  const q = query(colRef, typeFilter, limitFilter)

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckMaterial)
  })
}

export const getSlideDeckMaterials = (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    slideDeckId: params.slideDeckId,
  })
  const q = query(colRef, orderBy('materialOrder'))
  return modelListStream(repository, q, SlideDeckMaterial)
}

export const fetchSlideDeckMaterials = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, params)

  const snapshot = await getDocs(colRef)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckMaterial)
  })
}

export const fetchSlideDeckMaterialsForStudent = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, params)

  const q = query(
    colRef,
    where('viewableByStudent', '==', true),
    orderBy('materialOrder')
  )

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckMaterial)
  })
}

export const fetchSlideDeckMaterialsForInstructor = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const colRef = getColRef(repository.firestore, params)

  const q = query(
    colRef,
    where('viewableByInstructor', '==', true),
    orderBy('materialOrder')
  )

  const snapshot = await getDocs(q)

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckMaterial)
  })
}

export const buildEmptySlideDeckMaterialCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: SlideDeckMaterial,
    empty: empty,
  })
}

export const buildEmptySlideDeckMaterial = (repository: FirebaseRepository) => {
  return new StaticModelDocument({
    repository,
    model: SlideDeckMaterial,
    empty: empty,
  }).model
}

/**
 * Sort the materials in the slide_deck by the materialOrder field and update
 * the materialOrder field with the index of the material in the list so that
 * the materialOrder is always correct and the materials are always sorted by
 * the materialOrder field when the slide_deck is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortSlideDeckMaterials = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    slideDeckId,
  }: {
    slideDeckId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const materialIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = materialIds.splice(oldIndex, 1)[0]

    //insert at new index
    materialIds.splice(newIndex, 0, targetId)
  }

  const batch = writeBatch(repository.firestore)
  materialIds.forEach((materialId, index) => {
    // get the material doc ref
    const materialRef = doc(
      getColRef(repository.firestore, { slideDeckId }),
      materialId
    )
    batch.update(materialRef, { materialOrder: index })
  })

  // perform the batch write
  await batch.commit()
}

/**
 * Upload a file for a slide deck material, function uses mime type on the fileData as well
 * as the materialType to validate and determine the field to be updated with the new URL.
 * the new URL is added to the material document as well as being returned from the function.
 *
 * Function will error if the material document does not exist or the mimeType of the file is
 * invalid for the given materialType
 */
export const uploadSlideDeckMaterialFile = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    materialId,
    materialType,
    file,
  }: {
    slideDeckId: string
    file: File
    materialId: string
    materialType: SlideDeckMaterialType
  }
) => {
  // get field and valid extensions from materialType
  const fields = materialUploadFieldsFromType(materialType)
  const fileMimeType = file.type

  // get field from extension find the valid extension and field from mime type of file
  const fieldToUse = Object.entries(fields).find(([, { mimeType }]) => {
    return mimeType === fileMimeType
  })

  // if no field found then throw error
  if (!fieldToUse) {
    throw new Error(
      `Invalid mime type ${fileMimeType} for material type ${materialType}`
    )
  }

  // upload the file to storage with valid mime type / extension
  const [field, { extension, mimeType }] = fieldToUse

  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/material/${materialId}.${extension}`
  )

  await uploadBytes(storageRef, file, {
    contentType: mimeType,
  })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  // add the url to the material document
  const materialRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    materialId
  )

  const materialUpdatePayload: { [key: string]: string | number } = {
    [field]: urlWithoutToken,
  }

  // for the rss endpoint, we don't consume this on the client, hence why its not in the schema
  if (materialType === SlideDeckMaterialType.mp3 && mimeType === 'audio/mpeg') {
    materialUpdatePayload['materialFileSize'] = 0
  }

  await updateDoc(materialRef, materialUpdatePayload)

  return urlWithoutToken
}

export type MaterialRequiredFieldsForUpload = Omit<
  FirestoreSlideDeckMaterial,
  'materialOrder' | 'updatedAt'
>

/**
 * save a slide deck material
 * if materialId is supplied then update the existing material
 * else if materialId is not supplied then create a new material
 *
 * @return {string} the materialId of the material that was saved
 */
export const saveSlideDeckMaterial = async (
  repository: FirebaseRepository,
  {
    materialFields,
    slideDeckId,
    materialId,
  }: {
    materialFields: MaterialRequiredFieldsForUpload
    slideDeckId: string
    materialId?: string
  }
) => {
  interface WriteInterface
    extends Omit<FirestoreSlideDeckMaterial, 'updatedAt'> {
    updatedAt: FieldValue
  }

  const dataToWrite: WriteInterface = {
    ...materialFields,
    updatedAt: serverTimestamp(),
    materialOrder: 999,
  }

  const ref = materialId
    ? doc(getColRef(repository.firestore, { slideDeckId }), materialId)
    : getColRef(repository.firestore, { slideDeckId })

  if (ref instanceof CollectionReference) {
    return await addDoc(ref, dataToWrite)
  }

  //@ts-expect-error - todo: not sure why the type system hates this
  return await updateDoc(ref, dataToWrite)
}

export const deleteSlideDeckMaterialFile = async (
  repository: FirebaseRepository,
  {
    fieldName,
    slideDeckId,
    materialId,
    materialType,
    skipFieldUpdates = false,
  }: {
    fieldName: 'materialLink' | 'imageUrl'
    slideDeckId: string
    materialId: string
    materialType: SlideDeckMaterialType
    errorOnNotFound?: boolean
    skipFieldUpdates?: boolean
  }
) => {
  const fields = materialUploadFieldsFromType(materialType)[fieldName]
  if (!fields) {
    throw new Error(
      `Invalid field ${fieldName} for material type ${materialType}`
    )
  }
  const { extension, mimeType } = fields
  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/material/${materialId}.${extension}`
  )

  await safeDeleteStorageObject(storageRef)

  if (skipFieldUpdates) return

  // update the material doc, delete the fields
  const deleteFieldValues = {
    [fieldName]: deleteField(),
  }

  if (materialType === SlideDeckMaterialType.mp3 && mimeType === 'audio/mpeg') {
    deleteFieldValues['materialFileSize'] = deleteField()
  }

  const materialRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    materialId
  )

  await updateDoc(materialRef, deleteFieldValues)
}

export const deleteSlideDeckMaterial = async (
  repository: FirebaseRepository,
  {
    materialId,
    slideDeckId,
    materialType,
  }: {
    materialId: string
    slideDeckId: string
    materialType: SlideDeckMaterialType
  }
) => {
  // get the file types / fields associated with the material type
  const associatedFilePaths = materialUploadFieldsFromType(materialType)

  // make requests to delete the associated files
  const deleteFutures = Object.keys(associatedFilePaths).map((fieldName) => {
    const fieldNameTyped = fieldName as keyof typeof associatedFilePaths
    return deleteSlideDeckMaterialFile(repository, {
      fieldName: fieldNameTyped,
      slideDeckId,
      materialId,
      materialType,
      errorOnNotFound: false,
      skipFieldUpdates: true,
    })
  })

  // make request to delete the material file
  deleteFutures.push(
    deleteDoc(doc(getColRef(repository.firestore, { slideDeckId }), materialId))
  )
  await Promise.all(deleteFutures)
}
