import {
  addDoc,
  collection,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreInvitation } from './schema'
import { schema } from './schema'
import { InvitationType } from '../../types'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { DateTime } from 'luxon'

const converter: FirestoreDataConverter<FirestoreInvitation> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export async function createStudentInvitation(
  repository: FirebaseRepository,
  {
    sectionId,
    type,
  }: {
    sectionId: string
    type?: InvitationType
  }
) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    sectionId: sectionId,
    userId: repository.uid,
    updatedAt: new Date(),
    oneTime: false,
  }

  if (type === InvitationType.monthly) {
    data.expiresAt = DateTime.now().plus({ month: 1 }).toJSDate()
  } else {
    data.oneTime = true
  }

  return addDoc(invitationsRef, data)
}

export async function createTaInvitation(repository: FirebaseRepository) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    role: 'ta',
    expiresAt: DateTime.now().plus({ day: 30 }).toJSDate(),
    oneTime: true,
    userId: repository.uid,
    updatedAt: new Date(),
  }

  return addDoc(invitationsRef, data)
}

export async function createSectionInvittion(repository: FirebaseRepository) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    role: 'ta',
    expiresAt: DateTime.now().plus({ day: 30 }).toJSDate(),
    oneTime: true,
    userId: repository.uid,
    updatedAt: new Date(),
  }

  return addDoc(invitationsRef, data)
}

/// create role invitation with catalog
export const createInvitationInstructorWithCatalog = (
  repository: FirebaseRepository,
  { catalogId, oneTime = true }: { catalogId: string; oneTime?: boolean }
) => {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    catalogId: catalogId,
    oneTime: true,
    role: 'instructor',
    userId: repository.uid,
    updatedAt: new Date(),
  }
  if (!oneTime) {
    delete data.oneTime
    data.expiresAt = DateTime.now().plus({ day: 30 }).toJSDate()
  }

  return addDoc(invitationsRef, data)
}
