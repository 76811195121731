// User object that is shared publicly

import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateEngagement,
} from '../firestore/RoomStateEngagement'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomStateEngagement extends ObservableModelWithDecorators<FirestoreRoomStateEngagement> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateEngagement>
  ) {
    super(repository, doc)
  }
}
