import z from 'zod'

export const schema = z.object({
  protected: z.array(z.string()),
})

export const empty = Object.freeze(
  schema.parse({
    protected: [],
  })
)

export type FirestoreSettingsCatalogs = z.infer<typeof schema>
