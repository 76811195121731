import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
// required String slideDeckId,
// required String userId,
// int? updatedAt,
export const schema = z.object({
  slideDeckId: z.string(),
  userId: z.string(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    slideDeckId: '',
    userId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreUserProfilePurchase = z.infer<typeof schema>
