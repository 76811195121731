import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

export enum SectionState {
  notStarted = 0,
  inProgress = 1,
  completed = 2,
}

// this is the dart definition
// required String className,
// required String id,
// required int instructorUserId,
// required String sectionName,
// required SectionState sectionState,
// required bool shareable,
// required List<String> userIds,
// required List<PublicUser> users,
// bool? invoiced,
// int? updatedAt,
export const schema = z.object({
  instructorUserId: z.string(),
  className: z.string(),
  sectionName: z.string(),
  sectionState: z.nativeEnum(SectionState).default(SectionState.notStarted),
  userIds: z.array(z.string()).default([]),
  updatedAt: firestoreTimestamp,
  invoiced: z.boolean().nullish().default(false).optional(),
  shareable: z.boolean().nullish().default(false).optional(),
})

export const writeSchema = schema.extend({
  scheduledAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    instructorUserId: '',
    className: '',
    sectionName: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSectionInferredType = z.infer<typeof schema>

export interface FirestoreSection extends FirestoreSectionInferredType {}
