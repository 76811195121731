import { getRoomState } from '../../firestore/RoomState'
import {
  getRoomStateAnswers,
  getRoomStateAnswersForGroup,
} from '../../firestore/RoomStateAnswer'
import { getRoomStateFeedback } from '../../firestore/RoomStateFeedback'
import { getRoomStateSummary } from '../../firestore/RoomStateSummary'
import { fetchSettingsProcessingVideo } from '../../firestore/SettingsProcessingVideo'
import { fetchSlides } from '../../firestore/Slide'
import { fetchSlideCaptions } from '../../firestore/SlideCaption'
import { fetchSlideDeck } from '../../firestore/SlideDeck'
import { fetchSlideDeckExhibits } from '../../firestore/SlideDeckExhibit'
import { fetchSlideQuestions } from '../../firestore/SlideQuestion'
import { fetchUserProfileRoomToken } from '../../firestore/UserProfileRoomToken'
import type { BreakoutUser } from '../../models/BreakoutUser'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { RoomState } from '../../models/RoomState'
import { RoomStateAnswer } from '../../models/RoomStateAnswer'
import type { MeetingCubit } from '../MeetingCubit'
import { Cubit } from '../core'

/**
 * The MeetingDataFetcher class is responsible for fetching data from Firestore
 * and updating the Meeting model with the fetched data.
 *
 * The idea is to break up the Meeting class into smaller, more manageable pieces.
 */
export class MeetingDataFetcher extends Cubit {
  repository: FirebaseRepository
  currentUser: BreakoutUser
  constructor(protected meeting: MeetingCubit) {
    super()
    this.repository = meeting.repository
    this.currentUser = meeting.currentUser
  }

  start() {
    this.addStream(
      getRoomState(this.repository, { roomStateId: this.meeting.roomId }),
      (newRoomState) => {
        if (newRoomState.data === undefined) return

        this.meeting.updateRoomState(newRoomState)

        this.getRoomStateDependentData(newRoomState)
      }
    )

    this.addReaction({
      whenThisChanges: () => this.meeting.roomState.data.activeSlide,
      thenRunThisCode: () => this.resetCaptions(),
    })

    // we only need to subscribe to the group answers once
    this.addGroupAnswersStream()

    this.fetchToken()
    this.fetchSettingsProcessingVideo()
  }

  async resetCaptions() {
    this.meeting.slideCaptions.replaceModels([])

    const slideId = this.meeting.currentSlideId
    if (slideId) {
      const newCaptions = await fetchSlideCaptions(this.meeting.repository, {
        slideDeckId: this.meeting.slideDeckId,
        slideId: this.meeting.currentSlideId,
      })

      this.meeting.slideCaptions.replaceModels(newCaptions)
    }
  }

  async getRoomStateDependentData(roomState: RoomState) {
    // These are related ot the slide deck and they are immutable
    // so we only need to fetch them once
    this.fetchSlideDeck(roomState.data.slideDeckId)
    this.fetchExhibits(roomState.data.slideDeckId)
    this.fetchSlides(roomState.data.slideDeckId)
    this.fetchQuestions(roomState.data.slideDeckId)

    this.addUserAnswersStreams()
    this.addRoomStateFeedbackStream()
    this.addRoomStateSummaryStream()
  }

  async fetchToken() {
    const roomToken = await fetchUserProfileRoomToken(this.meeting.repository, {
      userId: this.currentUser.uid,
      roomId: this.meeting.roomId,
    })

    this.meeting.roomToken.replaceModel(roomToken)
  }

  async fetchSettingsProcessingVideo() {
    const setting = await fetchSettingsProcessingVideo(this.meeting.repository)

    this.meeting.settingsProcessingVideo.replaceModel(setting)
  }

  addUserAnswersStreams() {
    const userIds = this.meeting.roomState.data.userIds
    for (const userId of userIds) {
      this.addUserAnswersStream(userId)
    }
  }

  addUserAnswersStream(userId: string) {
    const key = `userAnswers-${userId}`
    // once the stream is added, we don't need to add it again
    // we don't remove user streams as we very rarely (or never) remove users during the meeiting
    if (this.hasStream(key)) return

    this.addStream(
      getRoomStateAnswers(this.repository, {
        roomId: this.meeting.roomId,
        userId: userId,
      }),
      (answers) => {
        const found = this.meeting.roomStateAnswersPerUser.get(userId)
        if (found) {
          found.replaceModels(answers)
        } else {
          const empty = RoomStateAnswer.emptyCollection(this.repository)
          empty.replaceModels(answers)
          this.meeting.roomStateAnswersPerUser.set(userId, empty)
        }
      },
      { name: key, namespace: 'userAnswers' }
    )
  }

  addGroupAnswersStream() {
    if (this.hasStream('groupAnswers')) return

    this.addStream(
      getRoomStateAnswersForGroup(this.repository, {
        roomId: this.meeting.roomId,
      }),
      (answers) => {
        this.meeting.roomStateAnswersForGroup.replaceModels(answers)
      }
    )
  }

  addRoomStateFeedbackStream() {
    if (this.hasStream('feedback')) return

    this.addStream(
      getRoomStateFeedback(this.repository, {
        roomId: this.meeting.roomId,
        userId: this.currentUser.uid,
      }),
      (feedback) => {
        this.meeting.roomStateFeedback.replaceModel(feedback)
      }
    )
  }

  addRoomStateSummaryStream() {
    if (this.hasStream('summary')) return

    this.addStream(
      getRoomStateSummary(this.repository, {
        roomId: this.meeting.roomId,
      }),
      (summary) => {
        this.meeting.roomStateSummary.replaceModel(summary)
      }
    )
  }

  private slideDeckFetched?: string
  async fetchSlideDeck(slideDeckId: string) {
    // guard - only fetch the slide deck once
    if (this.slideDeckFetched === slideDeckId) return
    this.slideDeckFetched = slideDeckId

    try {
      const slideDeck = await fetchSlideDeck(this.meeting.repository, {
        slideDeckId: slideDeckId,
      })

      this.meeting.slideDeck.replaceModel(slideDeck)
    } catch (e) {
      // but if we fail, we should try again next time
      this.slideDeckFetched = undefined
    }
  }

  private slidesFetched?: string
  async fetchSlides(slideDeckId: string) {
    // guard - only fetch the slides once
    if (this.slidesFetched === slideDeckId) return
    this.slidesFetched = slideDeckId

    try {
      const slides = await fetchSlides(this.meeting.repository, {
        slideDeckId: slideDeckId,
      })

      this.meeting.slideDeckSlides.replaceModels(slides)
      // this.meeting.slides = slides.map((model, index) =>
      //   this.resolveSlide(model, index)
      // )
    } catch (e) {
      console.error(e)
      // but if we fail, we should try again next time
      this.slidesFetched = undefined
    }
  }

  private exhibitsFetched?: string
  async fetchExhibits(slideDeckId: string) {
    // guard - only fetch the exhibits  once
    if (this.exhibitsFetched === slideDeckId) return
    this.exhibitsFetched = slideDeckId

    try {
      const exhibits = await fetchSlideDeckExhibits(this.meeting.repository, {
        slideDeckId: slideDeckId,
      })

      this.meeting.slideDeckExhibits.replaceModels(exhibits)
    } catch (e) {
      // but if we fail, we should try again next time
      this.exhibitsFetched = undefined
    }
  }

  private questionsFetched?: string
  async fetchQuestions(slideDeckId: string) {
    // guard - only fetch the exhibits  once
    if (this.questionsFetched === slideDeckId) return
    this.questionsFetched = slideDeckId

    try {
      const questions = await fetchSlideQuestions(this.meeting.repository, {
        slideDeckId: slideDeckId,
      })

      this.meeting.slideDeckQuestions.replaceModels(questions)

      // TODO: here subscribe to answers for each question
    } catch (e) {
      // but if we fail, we should try again next time
      this.questionsFetched = undefined
    }
  }
}
