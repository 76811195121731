import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  collection,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreRoomMessage, FirestoreRoomMessageWrite } from './schema'
import { FirestoreRoomMessageType, schema, writeSchema } from './schema'
import { buildUnsupportedConverter } from '../shared/noops'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { modelListStream } from '../../firestore-mobx/stream'
import { RoomMessage } from '../../models/RoomMessage'

export * from './schema'

const unsupported = buildUnsupportedConverter<FirestoreRoomMessage>()

const readConverter: FirestoreDataConverter<FirestoreRoomMessage> = {
  toFirestore: unsupported.toFirestore,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const writeConverter: FirestoreDataConverter<FirestoreRoomMessageWrite> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreRoomMessageWrite>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: unsupported.fromFirestore,
}

const getReadCollectionRef = (
  firestore: Firestore,
  roomId: string
): CollectionReference<FirestoreRoomMessage, DocumentData> => {
  return collection(firestore, 'rooms', roomId, 'messages').withConverter(
    readConverter
  )
}

const getWriteCollectionRef = (
  firestore: Firestore,
  roomId: string
): CollectionReference<FirestoreRoomMessageWrite, DocumentData> => {
  return collection(firestore, 'rooms', roomId, 'messages').withConverter(
    writeConverter
  )
}

export const getRoomMessages = (
  repository: FirebaseRepository,
  params: {
    roomId: string
  }
) => {
  const ref = getReadCollectionRef(repository.firestore, params.roomId)

  return modelListStream(repository, ref, RoomMessage)
}

export const sendMessage = async (
  firestore: Firestore,
  roomId: string,
  userId: string,
  text: string
) => {
  const message = {
    authorId: userId,
    text,
    type: FirestoreRoomMessageType.Text,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  }

  const messagesRef = getWriteCollectionRef(firestore, roomId)

  await addDoc(messagesRef, message)
}
