import { action, computed, makeObservable, observable } from 'mobx'

import { getSlides } from '../firestore/Slide'
import {
  deepCopySlideDeck,
  getSlideDeck,
  saveSlideDeckForm,
  addCatalogToSlideDeck,
  updateSlideDeckFeatured,
  removeCatalogFromSlideDeck,
  uploadSlideDeckImage,
  deleteSlideDeckImage,
  deleteSlideDeck,
} from '../firestore/SlideDeck'
import { getSlideDecksOfType } from '../firestore/SlideDeck'
import type { ExhibitFieldsForUpload } from '../firestore/SlideDeckExhibit'
import {
  deleteSlideDeckExhibit,
  deleteSlideDeckExhibitImage,
  getSlideDeckExhibits,
  saveSlideDeckExhibit,
  uploadSlideDeckExhibitImage,
} from '../firestore/SlideDeckExhibit'
import {
  deleteSlideDeckMaterial,
  deleteSlideDeckMaterialFile,
  getSlideDeckMaterials,
  saveSlideDeckMaterial,
  sortSlideDeckMaterials,
  uploadSlideDeckMaterialFile,
} from '../firestore/SlideDeckMaterial'
import {
  deleteSlide,
  deleteSlideImage,
  deleteSlideVideo,
  saveSlide,
  sortSlides,
  uploadSlideImage,
  uploadSlideVideo,
} from '../firestore/Slide'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import { SlideDeckExhibit } from '../models/SlideDeckExhibit'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideModel } from '../models/SlideModel'
import { type StaticModelCollection } from '../types'
import { SlideDeckMaterialType } from '../types'
import { Cubit } from './core'
import { SlideDeckAuthor } from '../models/SlideDeckAuthor'
import type { AuthorFieldsForUpload } from '../firestore/SlideDeckAuthor'
import {
  deleteSlideDeckAuthor,
  deleteSlideDeckAuthorImage,
  getSlideDeckAuthors,
  saveSlideDeckAuthor,
  sortSlideDeckAuthors,
  uploadSlideDeckAuthorImage,
} from '../firestore/SlideDeckAuthor'
import type { QuestionFieldsForUpload } from '../firestore/SlideQuestion'
import {
  deleteSlideDeckQuestion,
  getSlideQuestions,
  saveSlideDeckQuestion,
} from '../firestore/SlideQuestion'
import { SlideQuestion } from '../models/SlideQuestion'
import { SlideRubric } from '../models/SlideRubric'
import {
  assignSlideRubricToSlide,
  deleteSlideRubric,
  getSlideRubricStream,
  saveSlideRubric,
} from '../firestore/SlideRubric'
import { getSettingsSlideDeckFields } from '../firestore/SettingsSlideDeckFields'
import { SettingsSlideDeckFields } from '../models/SettingsSlideDeckFields'
import { Catalog } from '../models/Catalog'
import { getCatalogs } from '../firestore/Catalog'

export enum AdminSlideDeckTab {
  experienceDetails = 'experience_details',
  slides = 'slides',
  interactiveElements = 'interactive_elements',
  rubrics = 'rubrics',
  exhibits = 'exhibits',
  courseMaterials = 'course_materials',
  authors = 'authors',
}

export class AdminSlideDeckCubit extends Cubit {
  repository: FirebaseRepository

  slideDeck: SlideDeck
  slideDeckId: string

  materials: StaticModelCollection<SlideDeckMaterial>
  exhibits: StaticModelCollection<SlideDeckExhibit>
  settingsSlideDeckFields: SettingsSlideDeckFields
  private _catalogs: StaticModelCollection<Catalog>
  private _slides: StaticModelCollection<SlideModel>
  private _authors: StaticModelCollection<SlideDeckAuthor>
  private _questions: StaticModelCollection<SlideQuestion>
  private _rubrics: StaticModelCollection<SlideRubric>
  slideDecksOfType: StaticModelCollection<SlideDeck>

  @observable
  tab: AdminSlideDeckTab = AdminSlideDeckTab.experienceDetails

  constructor(repository: FirebaseRepository, slideDeckId: string) {
    super()
    makeObservable(this)

    this.slideDeckId = slideDeckId

    this.repository = repository
    this.slideDeck = SlideDeck.empty(repository)

    this.materials = SlideDeckMaterial.emptyCollection(repository)
    this.exhibits = SlideDeckExhibit.emptyCollection(repository)
    this.settingsSlideDeckFields = SettingsSlideDeckFields.empty(repository)
    this._catalogs = Catalog.emptyCollection(repository)
    this._slides = SlideModel.emptyCollection(repository)
    this._authors = SlideDeckAuthor.emptyCollection(repository)
    this._questions = SlideQuestion.emptyCollection(repository)
    this._rubrics = SlideRubric.emptyCollection(repository)
    this.slideDecksOfType = SlideDeck.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSlideDeck(this.repository, {
        slideDeckId: this.slideDeckId,
      }),
      (slideDeck) => {
        const lastType = this.slideDeck.data.slideDeckTypeId
        const updatedType = slideDeck.data.slideDeckTypeId

        // if the type has changed, we need to update the slideDecksOfType collection
        if (lastType !== updatedType) {
          const keyFromTypeId = (typeId: string) => `slideDecksOfType-${typeId}`
          this.removeStream(keyFromTypeId(lastType))
          this.addStream(
            getSlideDecksOfType(this.repository, {
              slideDeckTypeId: updatedType,
            }),
            (slideDecks) => {
              this.slideDecksOfType.replaceModels(slideDecks)
            },
            { name: keyFromTypeId(updatedType) }
          )
        }
        this.slideDeck.replaceModel(slideDeck)
      }
    )

    // load materials on init since we need them for the details tab
    // not just the course materials tab
    this.addStream(
      getSlideDeckMaterials(this.repository, { slideDeckId: this.slideDeckId }),
      (materials) => {
        this.materials.replaceModels(materials)
      }
    )

    this.addStream(
      getSlideDeckExhibits(this.repository, { slideDeckId: this.slideDeckId }),
      (exhibits) => {
        this.exhibits.replaceModels(exhibits)
      }
    )

    this.addStream(
      getSlides(this.repository, { slideDeckId: this.slideDeckId }),
      (slides) => {
        this.slides.replaceModels(slides)
      }
    )
    this.addStream(
      getSettingsSlideDeckFields(this.repository),
      (deckFields) => {
        this.settingsSlideDeckFields.replaceModel(deckFields)
      },
      {
        name: 'settings-slide-deck-fields',
      }
    )
  }

  /**
   * does not include the current slide deck
   */
  @computed
  get existingSlideDeckVersions() {
    const existingVersions: Set<string> = new Set()
    for (const slideDeck of this.slideDecksOfType.models) {
      // ignore self
      if (slideDeck.id === this.slideDeckId) continue
      existingVersions.add(slideDeck.data.slideDeckVersion)
    }
    return existingVersions
  }

  /**
   * does not include the current slide deck
   */
  @computed
  get slideDecksOfTypeByCatalogId() {
    const slideDecksByCatalog: Record<string, SlideDeck[]> = {}
    for (const slideDeck of this.slideDecksOfType.models) {
      // ignore self
      if (slideDeck.id === this.slideDeckId) continue
      for (const catalogId of slideDeck.data.catalogIds) {
        if (!slideDecksByCatalog[catalogId]) {
          slideDecksByCatalog[catalogId] = []
        }
        slideDecksByCatalog[catalogId].push(slideDeck)
      }
    }
    return slideDecksByCatalog
  }

  @computed
  get catalogs() {
    const name = 'catalogs'
    if (!this.hasStream(name)) {
      this.addStream(
        getCatalogs(this.repository),
        (catalogs) => {
          this._catalogs.replaceModels(catalogs)
        },
        { name }
      )
    }
    return this._catalogs
  }

  @computed
  get questions(): StaticModelCollection<SlideQuestion> {
    if (this.hasStream('questions')) {
      return this._questions
    }

    this.addStream(
      getSlideQuestions(this.repository, { slideDeckId: this.slideDeckId }),
      (questions) => {
        this._questions.replaceModels(questions)
      },
      {
        name: 'questions',
      }
    )

    return this._questions
  }

  @computed
  get sortedQuestions(): SlideQuestion[] {
    const slideIds = this.slides.models.map((e) => e.id)

    // Ensure the pre-meeting quiz is always first.
    slideIds.unshift('preMeetingQuizKey')

    return this.questions.models.sort((a, b) => {
      const aIndex = slideIds.indexOf((a.data.slideId || a.data.groupSlideId)!)
      const bIndex = slideIds.indexOf((b.data.slideId || b.data.groupSlideId)!)

      if (aIndex === bIndex) {
        // Compare the question strings of a and b.
        return a.data.question.localeCompare(b.data.question)
      }

      return aIndex - bIndex
    })
  }

  @computed
  get slides(): StaticModelCollection<SlideModel> {
    const slidesKey = 'admin-slides'
    if (this.hasStream(slidesKey)) {
      return this._slides
    }

    this.addStream(
      getSlides(this.repository, { slideDeckId: this.slideDeckId }),
      (slides) => {
        this._slides.replaceModels(slides)
      },
      {
        name: slidesKey,
      }
    )

    return this._slides
  }

  @computed
  get authors() {
    if (this.hasStream('authors')) {
      return this._authors
    }

    this.addStream(
      getSlideDeckAuthors(this.repository, { slideDeckId: this.slideDeckId }),
      (authors) => {
        this._authors.replaceModels(authors)
      },
      {
        name: 'authors',
      }
    )

    return this._authors
  }

  @computed
  get rubrics() {
    const rubricsKey = 'rubrics'
    if (!this.hasStream(rubricsKey)) {
      this.addStream(
        getSlideRubricStream(this.repository, {
          slideDeckId: this.slideDeckId,
        }),
        (rubrics) => {
          this._rubrics.replaceModels(rubrics)
        },
        {
          name: rubricsKey,
        }
      )
    }
    // sort rubrics by slideId
    return this._rubrics
  }

  @computed
  get rubricsSortedBySlideId() {
    // empty string is 'All Slides' option, must be first
    const slideIds = ['', ...this.slides.models.map((slide) => slide.id)]
    return this.rubrics.models.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId || '')
      const bIndex = slideIds.indexOf(b.data.slideId || '')
      if (aIndex === bIndex) {
        return a.data.rubric.localeCompare(b.data.rubric)
      }
      return aIndex - bIndex
    })
  }

  @computed
  get meetsFeaturedRequirements() {
    const hasFeaturedImageMaterial = this.materials.models.some(
      (material) =>
        material.data.materialType === SlideDeckMaterialType.featuredLarge &&
        !!material.data.materialLink
    )

    const slideDeckInHealthyState =
      !!this.slideDeck.data.slideDeckName &&
      !!this.slideDeck.data.slideDeckImageURL &&
      ![SlideDeckState.deleted, SlideDeckState.hidden].includes(
        this.slideDeck.slideDeckState
      )
    return (
      this.slideDeck.data.slideDeckFeatured ||
      (hasFeaturedImageMaterial && slideDeckInHealthyState)
    )
  }

  @action
  changeTab(tab: AdminSlideDeckTab): void {
    this.tab = tab
  }

  @computed
  get featuredSlideDecksOfType() {
    return this.slideDecksOfType.models.filter(
      (slideDeck) =>
        slideDeck.data.slideDeckFeatured && slideDeck.id !== this.slideDeckId
    )
  }

  /****************************************************************
   *  Materials
   ***************************************************************/

  reorderSlideDeckMaterials = async (
    currentOrder: string[],
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): Promise<void> => {
    await sortSlideDeckMaterials(this.repository, {
      currentOrder,
      oldIndex,
      newIndex,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideDeckMaterial = async (
    materialId: string,
    materialType: SlideDeckMaterialType
  ): Promise<void> => {
    await deleteSlideDeckMaterial(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialType,
    })
  }

  /** field name is the name of the material field which holds the URL for the uploaded resource
   * should be **materialLink** in all cases except podcast images
   */
  deleteSlideDeckMaterialFile = async ({
    fieldName,
    materialId,
    materialType,
  }: {
    fieldName: 'materialLink' | 'imageUrl'
    materialId: string
    materialType: SlideDeckMaterialType
  }) => {
    await deleteSlideDeckMaterialFile(this.repository, {
      slideDeckId: this.slideDeckId,
      fieldName,
      materialId,
      materialType,
    })
  }

  /** if materialId is not present, creates a new material document and returns the ID */
  saveSlideDeckMaterial = async ({
    materialFields,
    materialId,
  }: Omit<Parameters<typeof saveSlideDeckMaterial>[1], 'slideDeckId'>) => {
    return await saveSlideDeckMaterial(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialFields,
    })
  }

  /**
   * returns the url of the uploaded material
   */
  uploadSlideDeckMaterialFile = async ({
    materialId,
    materialType,
    file,
  }: Omit<
    Parameters<typeof uploadSlideDeckMaterialFile>[1],
    'slideDeckId'
  >) => {
    return await uploadSlideDeckMaterialFile(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialType,
      file,
    })
  }

  /****************************************************************
   *  Slide
   ***************************************************************/
  saveSlide = async (
    params: Omit<Parameters<typeof saveSlide>[1], 'slideDeckId'>
  ) => {
    return await saveSlide(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlide = async (
    params: Omit<Parameters<typeof deleteSlide>[1], 'slideDeckId'>
  ) => {
    return await deleteSlide(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideVideo = async (
    params: Omit<Parameters<typeof uploadSlideVideo>[1], 'slideDeckId'>
  ) => {
    return await uploadSlideVideo(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideImage = async (
    params: Omit<Parameters<typeof uploadSlideImage>[1], 'slideDeckId'>
  ) => {
    return await uploadSlideImage(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideVideo = async (
    params: Omit<Parameters<typeof deleteSlideVideo>[1], 'slideDeckId'>
  ) => {
    return await deleteSlideVideo(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideImage = async (
    params: Omit<Parameters<typeof deleteSlideImage>[1], 'slideDeckId'>
  ) => {
    return await deleteSlideImage(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  sortSlides = async (
    params: Omit<Parameters<typeof sortSlides>[1], 'slideDeckId'>
  ) => {
    return await sortSlides(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Author
   ***************************************************************/
  uploadSlideDeckAuthorImage = async ({
    authorId,
    file,
  }: {
    authorId: string
    file: File
  }) => {
    return await uploadSlideDeckAuthorImage(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
      file,
    })
  }

  /**
   * Returns an authorId.
   */
  saveSlideDeckAuthor = async ({
    authorFields,
    authorId,
  }: {
    authorId?: string
    authorFields: AuthorFieldsForUpload
  }) => {
    return await saveSlideDeckAuthor(this.repository, {
      slideDeckId: this.slideDeckId,
      authorFields,
      authorId,
    })
  }

  deleteSlideDeckAuthor = async (authorId: string) => {
    await deleteSlideDeckAuthor(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
    })
  }

  deleteSlideDeckAuthorImage = async (authorId: string) => {
    await deleteSlideDeckAuthorImage(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
    })
  }

  reorderSlideDeckAuthors = async (
    currentOrder: string[],
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): Promise<void> => {
    await sortSlideDeckAuthors(this.repository, {
      currentOrder,
      oldIndex,
      newIndex,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideDeckExhibitImage = async ({
    exhibitId,
    file,
  }: {
    exhibitId: string
    file: File
  }) => {
    return await uploadSlideDeckExhibitImage(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
      file,
    })
  }

  /****************************************************************
   *  Exhibits
   ***************************************************************/

  saveSlideDeckExhibit = async ({
    exhibitFields,
    exhibitId,
  }: {
    exhibitId?: string
    exhibitFields: ExhibitFieldsForUpload
  }) => {
    return await saveSlideDeckExhibit(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitFields,
      exhibitId,
    })
  }

  deleteSlideDeckExhibit = async (exhibitId: string) => {
    await deleteSlideDeckExhibit(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
    })
  }

  deleteSlideDeckExhibitImage = async (exhibitId: string) => {
    await deleteSlideDeckExhibitImage(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
    })
  }

  /****************************************************************
   *  Questions/Interactive Elements
   ***************************************************************/

  saveSlideDeckQuestion = async ({
    questionFields,
    questionId,
  }: {
    questionId?: string
    questionFields: QuestionFieldsForUpload
  }) => {
    return await saveSlideDeckQuestion(this.repository, {
      slideDeckId: this.slideDeckId,
      questionFields,
      questionId,
    })
  }

  deleteSlideDeckQuestion = async (questionId: string) => {
    await deleteSlideDeckQuestion(this.repository, {
      slideDeckId: this.slideDeckId,
      questionId,
    })
  }

  deleteSlideRubric = async (
    params: Omit<Parameters<typeof deleteSlideRubric>[1], 'slideDeckId'>
  ) => {
    return await deleteSlideRubric(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Rubric
   ***************************************************************/

  saveSlideRubric = async (
    params: Omit<Parameters<typeof saveSlideRubric>[1], 'slideDeckId'>
  ) => {
    return await saveSlideRubric(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  assignSlideRubricToSlide = async (
    params: Omit<Parameters<typeof assignSlideRubricToSlide>[1], 'slideDeckId'>
  ) => {
    return await assignSlideRubricToSlide(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Slide Details
   ***************************************************************/

  saveSlideDeckForm = async (
    params: Partial<Parameters<typeof saveSlideDeckForm>[2]>
  ) => {
    const payload = {
      slideDeckDescription: this.slideDeck.data.slideDeckDescription,
      slideDeckDisciplines: this.slideDeck.data.slideDeckDisciplines,
      slideDeckLearningObjectives:
        this.slideDeck.data.slideDeckLearningObjectives,
      slideDeckIndustries: this.slideDeck.data.slideDeckIndustries,
      slideDeckFeatured: this.slideDeck.data.slideDeckFeatured,
      slideDeckGoogleTemplateURL:
        this.slideDeck.data.slideDeckGoogleTemplateURL,
      slideDeckName: this.slideDeck.data.slideDeckName,
      slideDeckPrice: this.slideDeck.data.slideDeckPrice,
      slideDeckTeaser: this.slideDeck.data.slideDeckTeaser,
      slideDeckVersion: this.slideDeck.data.slideDeckVersion,
      slideDeckImageURL: this.slideDeck.data.slideDeckImageURL,
      ...params,
    }
    return await saveSlideDeckForm(this.repository, this.slideDeckId, payload)
  }

  deepCopySlideDeck = async (
    params: Omit<Parameters<typeof deepCopySlideDeck>[1], 'slideDeck'>
  ) => {
    return await deepCopySlideDeck(this.repository, {
      ...params,
      slideDeck: this.slideDeck,
    })
  }

  uploadSlideDeckImage = async (file: File) => {
    return await uploadSlideDeckImage(this.repository, {
      slideDeckId: this.slideDeckId,
      file,
    })
  }

  deleteSlideDeckImage = async () => {
    return await deleteSlideDeckImage(this.repository, this.slideDeckId)
  }

  addCatalogToSlideDeck = async (
    params: Omit<Parameters<typeof addCatalogToSlideDeck>[1], 'slideDeckId'>
  ) => {
    return await addCatalogToSlideDeck(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  removeCatalogFromSlideDeck = async (
    params: Omit<
      Parameters<typeof removeCatalogFromSlideDeck>[1],
      'slideDeckId'
    >
  ) => {
    return await removeCatalogFromSlideDeck(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  replaceVersionsInCatalog = async ({
    catalogId,
    existingVersions,
    slideDeckIdsToUnFeature,
  }: {
    catalogId: string
    existingVersions: string[]
    slideDeckIdsToUnFeature: string[]
  }) => {
    const unFeatureExistingVersions = slideDeckIdsToUnFeature.map(
      (slideDeckId) =>
        updateSlideDeckFeatured(this.repository, {
          slideDeckId,
          slideDeckFeatured: false,
        })
    )
    const removeCatalogFromExistingVersion = existingVersions.map(
      (slideDeckId) =>
        removeCatalogFromSlideDeck(this.repository, { slideDeckId, catalogId })
    )
    const addCatalogToCurrentSlideDeck = addCatalogToSlideDeck(
      this.repository,
      { slideDeckId: this.slideDeckId, catalogId }
    )

    await Promise.all(unFeatureExistingVersions)
    await Promise.all([
      ...removeCatalogFromExistingVersion,
      addCatalogToCurrentSlideDeck,
    ])
  }

  updateSlideDeckFeatured = async (
    params: Parameters<typeof updateSlideDeckFeatured>[1]
  ) => {
    return await updateSlideDeckFeatured(this.repository, {
      ...params,
    })
  }

  deleteSlideDeck = async (params: Parameters<typeof deleteSlideDeck>[1]) => {
    return await deleteSlideDeck(this.repository, {
      ...params,
    })
  }
}
