// User object that is shared publicly

import { computed, makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty, type FirestorePublicUser } from '../firestore/PublicUser'
import type { FirebaseRepository } from './FirebaseRepository'

export class PublicUser extends ObservableModelWithDecorators<FirestorePublicUser> {
  static empty(repository: FirebaseRepository, id?: string) {
    return emptyModel(repository, this, empty, id)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestorePublicUser>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get fullName(): string {
    return [this.data.firstName, this.data.lastName].filter(Boolean).join(' ')
  }

  // Getter to compute initials of the user, handling cases where names might be empty
  @computed
  get initials(): string {
    let initials = ''
    if (this.data.firstName.length > 0) {
      initials += this.data.firstName[0]
    }
    if (this.data.lastName.length > 0) {
      initials += this.data.lastName[0]
    }
    return initials.toUpperCase()
  }

  @computed
  get color(): string {
    return this.hashToColor()
  }

  private generateHash(): number {
    const data = this.id
    let hash = 0
    for (let i = 0; i < data.length; i++) {
      const char = data.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  private hashToColor(): string {
    return this.colors[this.generateHash() % this.colors.length]!
  }

  private colors: string[] = [
    '#ff6767',
    '#66e0da',
    '#f5a2d9',
    '#f0c722',
    '#6a85e5',
    '#fd9a6f',
    '#92db6e',
    '#73b8e5',
    '#fd7590',
    '#c78ae5',
  ]
}
