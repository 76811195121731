import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import type { TranscriptMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'

function sortTranscriptMessages(a: TranscriptMessage, b: TranscriptMessage) {
  return a.id > b.id ? 1 : -1
}

export class TranscriptController {
  meeting: MeetingCubit
  _messages: ObservableMap<number, TranscriptMessage> = observable.map({})

  constructor(meeting: MeetingCubit) {
    this.meeting = meeting

    makeObservable(this, {
      messages: computed,
      handleMessage: action,
    })
  }

  handleMessage(message: TranscriptMessage) {
    this._messages.set(message.id, message)

    // remove old messages
    if (this._messages.size > 10) {
      const array = Array.from(this._messages.values())
      const sorted = array.sort(sortTranscriptMessages)

      // find all message before last 10
      const toRemove = sorted.slice(0, sorted.length - 10)

      for (const message of toRemove) {
        this._messages.delete(message.id)
      }
    }
  }

  get messages() {
    const array = Array.from(this._messages.values())

    return array.sort(sortTranscriptMessages)
  }
}
