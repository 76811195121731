import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Cubit } from './core'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { Section } from '../models/Section'
import { getSections } from '../firestore/Section'
import { RoomState } from '../models/RoomState'
import { getRoomStatesForStudent } from '../firestore/RoomState'
import { getSlideDeck } from '../firestore/SlideDeck'
import { SlideDeck } from '../models/SlideDeck'
import { SectionAssignment } from '../models/SectionAssignment'
import { AssignmentType } from '../models/SectionAssignment'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import { ValidLibraryObject } from '../stores/ValidLibraryObject'
import { LibraryObjectState } from '../types'

export class StudentLibraryCubit extends Cubit {
  repository: FirebaseRepository

  sections: StaticModelCollection<Section>
  roomStates: StaticModelCollection<RoomState>
  slideDecksById = observable.map<string, SlideDeck>()
  assignmentsBySectionId = observable.map<
    string,
    StaticModelCollection<SectionAssignment>
  >()

  @observable showCompleted = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository

    this.sections = Section.emptyCollection(repository)
    this.roomStates = RoomState.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSections(this.repository),
      (sections) => {
        this.sections.replaceModels(sections)

        for (const section of sections) {
          this.addSectionAssignmentStream(section.id)
        }
      },
      {
        name: 'sections',
        onError: (error) => {
          console.error('Error getting sections', error)
          this.sections.replaceModels([])
        },
      }
    )

    this.addStream(
      getRoomStatesForStudent(this.repository),
      (roomStates) => {
        this.roomStates.replaceModels(roomStates)
      },
      {
        name: 'room-states',
        onError: (error) => {
          console.error('Error getting room states', error)
          this.roomStates.replaceModels([])
        },
      }
    )
  }

  addSectionAssignmentStream(sectionId: string): void {
    const key = `section-assignments-${sectionId}`
    this.addStream(
      getSectionAssignments(this.repository, { sectionId }),
      (assignments) => {
        const existing = this.assignmentsBySectionId.get(sectionId)
        if (existing) {
          existing.replaceModels(assignments)
        } else {
          const initial = SectionAssignment.emptyCollection(this.repository)
          initial.replaceModels(assignments)
          runInAction(() => {
            this.assignmentsBySectionId.set(sectionId, initial)
          })
        }

        for (const assignment of assignments) {
          this.addSlideDeckStream(assignment.data.slideDeckId)
        }
      },
      {
        name: key,
        onError: (error) => {
          console.error('Error getting assignments', error)
          this.roomStates.replaceModels([])
        },
      }
    )
  }

  addSlideDeckStream(slideDeckId: string): void {
    const slideDeckKey = `slide-deck-${slideDeckId}`
    // only add slide deck it once
    if (!this.hasStream(slideDeckKey)) {
      // console.log('adding slide deck stream', slideDeckKey)
      this.addStream(
        getSlideDeck(this.repository, { slideDeckId: slideDeckId }),
        (slideDeck) => {
          runInAction(() => {
            this.slideDecksById.set(slideDeck.id, slideDeck)
          })
        },
        {
          name: slideDeckKey,
          onError: (error) => {
            console.error(
              'Error getting slide deck for room state',
              slideDeckId,
              error
            )
          },
        }
      )
    }
  }

  @action
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  @computed
  get isLoading() {
    return (
      this.sections.isLoading ||
      this.roomStates.isLoading ||
      this.assignmentsBySectionId.size !== this.sections.models.length
    )
  }

  @computed
  get allSectionAssignments() {
    const assignmentsArray = Array.from(this.assignmentsBySectionId.values())
    return assignmentsArray.flatMap((assignments) => assignments.models)
  }

  @computed
  get libraryObjects() {
    const list: ValidLibraryObject[] = []

    for (const assignment of this.allSectionAssignments) {
      const foundSlideDeck = this.slideDecksById.get(
        assignment.data.slideDeckId
      )
      const slideDeck = foundSlideDeck || SlideDeck.empty(this.repository)

      const foundSection = this.sections.models.find(
        (section) => section.id === assignment.data.sectionId
      )
      const section = foundSection || Section.empty(this.repository)

      const roomStates = this.roomStates.models.filter(
        (roomState) => roomState.data.assignmentId === assignment.id
      )
      if (
        roomStates.length === 0 &&
        assignment.data.assignmentType === AssignmentType.studentLed
      ) {
        if (slideDeck) {
          list.push(
            new ValidLibraryObject({
              repository: this.repository,
              section,
              assignment,
              roomState: RoomState.empty(this.repository),
              slideDeck,
            })
          )
        }
      } else {
        for (const roomState of roomStates) {
          list.push(
            new ValidLibraryObject({
              repository: this.repository,
              section,
              assignment,
              roomState,
              slideDeck,
            })
          )
        }
      }
    }

    return list
  }

  @computed
  get sortedLibraryObjects() {
    return this.libraryObjects.sort((a, b) => {
      const aState = a.libraryObjectState
      const bState = b.libraryObjectState

      if (aState === bState) {
        if (aState === LibraryObjectState.invited) {
          // sort expiresAt ascending
          return compareDates(
            a.assignment.data.expiresAt,
            b.assignment.data.expiresAt
          )
        } else if (aState === LibraryObjectState.completed) {
          if (
            a.roomState.data.updatedAt === null ||
            b.roomState.data.updatedAt === null
          ) {
            // sort assignedAt descending
            return compareDates(
              b.assignment.data.assignedAt,
              a.assignment.data.assignedAt
            )
          }

          // sort updatedAt descending
          return compareDates(
            b.assignment.data.updatedAt,
            a.assignment.data.updatedAt
          )
        } else {
          // sort assignedAt descending
          return compareDates(
            b.assignment.data.assignedAt,
            a.assignment.data.assignedAt
          )
        }
      } else {
        return aState > bState ? 1 : -1
      }
    })
  }

  @computed
  get filteredLibraryObjects() {
    if (!this.showCompleted) {
      return this.sortedLibraryObjects.filter((libraryObject) => {
        return (
          libraryObject.libraryObjectState !== LibraryObjectState.completed &&
          libraryObject.libraryObjectState !== LibraryObjectState.expired
        )
      })
    }
    return this.sortedLibraryObjects
  }
}

function compareDates(a: Date, b: Date) {
  return a.getTime() - b.getTime()
}
