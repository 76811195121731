import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

export const schema = z.object({
  roomId: z.string(),
  userId: z.string(),
  technicalFeedback: z
    .array(z.string())
    .nullable()
    .default([])
    .transform((val) => {
      const value = val || []
      return value.sort((a: string, b: string) => b.length - a.length)
    }),
  professorFeedback: z
    .array(z.string())
    .nullable()
    .default([])
    .transform((val) => {
      const value = val || []
      return value.sort((a: string, b: string) => b.length - a.length)
    }),
  assignmentId: z.string().optional(),
  sectionId: z.string().optional(),
  updatedAt: firestoreTimestamp.optional(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    userId: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateProfessorFeedbackInferred = z.infer<typeof schema>
export interface FirestoreRoomStateProfessorFeedback
  extends FirestoreRoomStateProfessorFeedbackInferred {}
export type FirestoreRoomStateProfessorFeedbackWrite = z.infer<typeof schema>
