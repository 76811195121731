import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

// this is the dart definition
// required double cameraOffTime,
// required double durationPresent,
// required String roomId,
// required double totalTalkTime,
// required double totalTime,
// required String userId,
// required double userTalkTime,
// String assignmentId,
// String sectionId,
// int? updatedAt,
export const schema = z.object({
  // failing on fake AI
  cameraOffTime: z.number().optional(),
  // failing on fake AI
  durationPresent: z.number().optional(),
  roomId: z.string(),
  // failing on fake AI
  totalTalkTime: z.number().optional(),
  // failing on fake AI
  totalTime: z.number().optional(),
  userId: z.string(),
  // failing on fake AI
  userTalkTime: z.number().optional(),
  assignmentId: z.string().optional(),
  sectionId: z.string().optional(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    cameraOffTime: 0,
    durationPresent: 0,
    roomId: '',
    totalTalkTime: 0,
    totalTime: 0,
    userId: '',
    userTalkTime: 0,
    assignmentId: '',
    sectionId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateEngagementInferred = z.infer<typeof schema>
export type FirestoreRoomStateEngagement = FirestoreRoomStateEngagementInferred
export type FirestoreRoomStateEngagementWrite = z.infer<typeof schema>
