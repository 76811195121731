import z from 'zod'
import { firestoreTimestamp } from '../shared/schema'

// this is the dart definition
export const schema = z.object({
  expiresAt: firestoreTimestamp.optional(),
  sectionId: z.string().optional(),
  catalogId: z.string().optional(),
  oneTime: z.boolean(),
  role: z.string(),
  userId: z.string(),
  updatedAt: firestoreTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    expiresAt: { seconds: 0, nanoseconds: 0 },
    oneTime: false,
    role: '',
    userId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreInvitation extends z.infer<typeof schema> {}
