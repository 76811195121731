import {
  CollectionReference,
  type DocumentData,
  addDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore'
import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreSlide } from './schema'
import type { SlideType } from '../../types'
import { schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import { SlideModel } from '../../models/SlideModel'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'

export * from './schema'

const mediaTypePropertiesByMimeType = new Map([
  [
    'image/jpeg',
    {
      ext: 'jpg',
      path: 'image',
    },
  ],
  [
    'video/webm',
    {
      ext: 'webm',
      path: 'video',
    },
  ],
])

const converter: FirestoreDataConverter<FirestoreSlide> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { slideDeckId: string }
): CollectionReference<FirestoreSlide, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'slide'
  ).withConverter(converter)
}

export const fetchSlides = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const docRef = getColRef(repository.firestore, params)
  const q = query(docRef, orderBy('slideOrder'))

  const docs = await getDocs(q)
  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideModel)
  })
}

export const getSlides = (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const docRef = getColRef(repository.firestore, params)
  const q = query(docRef, orderBy('slideOrder'))
  return modelListStream(repository, q, SlideModel)
}

export const fetchSlideCount = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const colRef = getColRef(repository.firestore, params)
  const snap = await getCountFromServer(colRef)

  return snap.data().count
}

export const saveSlide = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideDescription,
    slideDuration,
    slideName,
    slideType,
    slideId,
  }: {
    slideDeckId: string
    slideDescription: string
    slideDuration: number
    slideName: string
    slideType: SlideType
    slideId?: string
  }
) => {
  const slideData: Partial<Record<keyof FirestoreSlide, unknown>> = {
    slideDescription: slideDescription,
    slideDuration: slideDuration,
    slideName: slideName,
    slideType: slideType,
    updatedAt: serverTimestamp(),
  }

  if (!slideId) {
    slideData.slideOrder = 999
  }

  const colRef = getColRef(repository.firestore, { slideDeckId: slideDeckId })
  const slideRef = slideId ? doc(colRef, slideId) : colRef

  if (slideRef instanceof CollectionReference) {
    return await addDoc(slideRef, slideData)
  }

  return await updateDoc(slideRef, slideData)
}

const deleteSlideFile = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
    path,
    fieldName,
  }: {
    slideDeckId: string
    slideId: string
    path: 'image' | 'video'
    fieldName: keyof FirestoreSlide
  }
) => {
  const slideRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    slideId
  )
  const fileRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/slide/${path}/${slideId}`
  )
  await safeDeleteStorageObject(fileRef)

  updateDoc(slideRef, {
    [fieldName]: deleteField(),
    slideVideoDuration: deleteField(),
    slideVideoError: deleteField(),
    slideStorageURL: deleteField(),
  })
}

export const deleteSlideImage = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof deleteSlideFile>[1], 'path' | 'fieldName'>
) => {
  return deleteSlideFile(repository, {
    ...params,
    path: 'image',
    fieldName: 'slideImageURL',
  })
}
export const deleteSlideVideo = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof deleteSlideFile>[1], 'path' | 'fieldName'>
) => {
  return deleteSlideFile(repository, {
    ...params,
    path: 'video',
    fieldName: 'slideVideoURL',
  })
}

export const deleteSlide = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
  }: {
    slideDeckId: string
    slideId: string
  }
) => {
  // delete media files first
  await Promise.all([
    deleteSlideImage(repository, { slideDeckId, slideId }),
    deleteSlideVideo(repository, { slideDeckId, slideId }),
  ])

  // delete slide document
  await deleteDoc(
    doc(getColRef(repository.firestore, { slideDeckId }), slideId)
  )
}

const uploadSlideFile = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
    file,
    fieldName,
  }: {
    file: File
    fieldName: keyof FirestoreSlide
    slideId: string
    slideDeckId: string
  }
) => {
  // get extension from file
  const mediaProps = mediaTypePropertiesByMimeType.get(file.type)
  if (!mediaProps) {
    throw new Error('Unsupported media type: ' + file.type)
  }
  const { ext, path } = mediaProps

  const fileRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/slide/${path}/${slideId}.${ext}`
  )
  await uploadBytes(fileRef, file, { contentType: file.type })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(fileRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  // write url to slide doc
  const slideRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    slideId
  )

  const updateData: Partial<Record<keyof FirestoreSlide, unknown>> = {
    [fieldName]: urlWithoutToken,
    updatedAt: serverTimestamp(),
    slideVideoDuration: deleteField(),
    slideVideoError: deleteField(),
    slideStorageURL: `gs://${fileRef.bucket}/${fileRef.fullPath}`,
  }

  await updateDoc(slideRef, updateData)
  return urlWithoutToken
}

export const uploadSlideVideo = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof uploadSlideFile>[1], 'fieldName'>
) => {
  return uploadSlideFile(repository, {
    ...params,
    fieldName: 'slideVideoURL',
  })
}

export const uploadSlideImage = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof uploadSlideFile>[1], 'fieldName'>
) => {
  return uploadSlideFile(repository, {
    ...params,
    fieldName: 'slideImageURL',
  })
}

export const sortSlides = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    slideDeckId,
  }: {
    slideDeckId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const slideIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = slideIds.splice(oldIndex, 1)[0]
    //insert at new index
    slideIds.splice(newIndex, 0, targetId)
  }
  const batch = writeBatch(repository.firestore)
  slideIds.forEach((slideId, index) => {
    // get the slide doc ref
    const slideRef = doc(
      getColRef(repository.firestore, { slideDeckId }),
      slideId
    )
    const orderUpdate: Partial<Record<keyof FirestoreSlide, unknown>> = {
      slideOrder: index,
    }
    batch.update(slideRef, orderUpdate)
  })

  // perform the batch write
  await batch.commit()
}

export type SlideFieldsForUpload = Pick<
  FirestoreSlide,
  | 'slideName'
  | 'slideType'
  | 'slideDescription'
  | 'slideDuration'
  | 'slideImageURL'
  | 'slideVideoURL'
>
