import { computed, makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreSectionAssignment,
} from '../firestore/SectionAssignment'
import { buildSlideDeckObservableModelDocument } from '../firestore/SlideDeck'
import type { FirebaseRepository } from './FirebaseRepository'
import { DateTime } from 'luxon'
import { AssignmentState } from '../types'

export class SectionAssignment extends ObservableModelWithDecorators<FirestoreSectionAssignment> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSectionAssignment>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get sectionId() {
    return this.unsafeData?.sectionId ?? ''
  }

  get expiresAt() {
    return this.unsafeData?.expiresAt ?? undefined
  }

  get assignmentState(): AssignmentState {
    // milliseconds in a day
    const oneDay = 1000 * 60 * 60 * 24
    if (this.expiresAt && this.expiresAt.getTime() + oneDay > Date.now()) {
      return AssignmentState.pending
    }
    return AssignmentState.completed
  }

  get expiresAtDate() {
    return this.expiresAt ? DateTime.fromJSDate(this.expiresAt) : undefined
  }

  @computed
  get isExpired() {
    return this.expiresAt
      ? this.expiresAt.getTime() < this.repository.currentMinute.toMillis()
      : false
  }

  @computed
  get slideDeck() {
    return buildSlideDeckObservableModelDocument(
      this.repository,
      this.data.slideDeckId
    ).model
  }

  @computed
  get assignmentType(): AssignmentType | undefined {
    if (!this.hasData) return undefined
    // return index of the rubric type enum
    switch (this.data.assignmentType) {
      case 0:
        return AssignmentType.studentLed
      case 1:
        return AssignmentType.instructorLed
      default:
        return undefined
    }
  }

  @computed
  get groupingType(): AssignmentGroupingType | undefined {
    if (!this.hasData) return undefined
    // return index of the rubric type enum
    switch (this.data.groupingType) {
      case 0:
        return AssignmentGroupingType.manual
      case 1:
        return AssignmentGroupingType.automaticRandom
      default:
        return undefined
    }
  }
}

export enum AssignmentType {
  /// studentLed
  studentLed,

  /// instructorLed
  instructorLed,
}

/// An enum that represents the type of assignment group.
export enum AssignmentGroupingType {
  /// manual
  manual,

  /// automaticRandom
  automaticRandom,
}
